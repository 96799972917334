import { useEffect, useMemo } from 'react';
import { useEntityManager, useDenormalizedState } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { SecurityPolicyType } from 'app/types';
import createProvider from './createProvider';

const securityPoliciesSchema = createUnionSchema(['securityPolicies']);

const useSecurityPolicies = () => {
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const [securityPolicies, setSecurityPolicies] = useDenormalizedState<SecurityPolicyType[]>(
    [],
    [securityPoliciesSchema]
  );

  useEffect(() => {
    async function fetchSecurityPolicies() {
      try {
        const data = await fetchEntityList(endpoints.SECURITY_POLICIES, {});
        setSecurityPolicies(data.result);
      } catch (error) {
        // nothing to do
      }
    }

    fetchSecurityPolicies();
  }, [endpoints.SECURITY_POLICIES, fetchEntityList, setSecurityPolicies]);

  const passwordRegexPolicy = useMemo(() => {
    return securityPolicies.find((i) => i.type === 'policy_password_regex');
  }, [securityPolicies]);

  return [{ securityPolicies, passwordRegexPolicy }];
};

const [withSecurityPolicies, useProvidedSecurityPolicies] = createProvider(useSecurityPolicies);

export { withSecurityPolicies };

export default useProvidedSecurityPolicies;
