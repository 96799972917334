import React, { ComponentType } from 'react';
import moment from 'moment';
import amber from '@material-ui/core/colors/amber';
import { SnackbarContent, makeStyles } from '@material-ui/core/';
import { Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText(amber[200]),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

type SubscriptionWaitingPaymentByCheckMessageProps = {
  studioBoxAssignation: any;
};

const SubscriptionWaitingPaymentByCheckMessage: ComponentType<SubscriptionWaitingPaymentByCheckMessageProps> = ({
  studioBoxAssignation,
}) => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          <Warning fontSize="small" className={classes.icon} />
          {`Abri pré-réservé jusqu'au ${moment(studioBoxAssignation.enddate).format(
            'DD/MM/YYYY'
          )} dans l'attente de réception de votre chèque.`}
        </span>
      }
    />
  );
};

export default SubscriptionWaitingPaymentByCheckMessage;
