import React, { ComponentType, useMemo } from 'react';
import MapCore from 'app/components/GoogleMap/MapCore';
import useConfig from 'app/hooks/useConfig';
import { useWidth } from 'app/hooks';

type MapProps = {
  filteredStudios?: any;
};

const Map: ComponentType<MapProps> = ({ filteredStudios }) => {
  const googleApiKey = useConfig((config) => config.googleMaps.apiKey);
  const width = useWidth();

  const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`;

  const loadingElement = useMemo(() => {
    if ('sm' === width) {
      return <div style={{ height: '325px' }} />;
    }

    if ('xs' === width) {
      return <div style={{ height: '285px' }} />;
    }

    return <div style={{ height: '400px' }} />;
  }, [width]);

  const containerElement = useMemo(() => {
    if ('sm' === width) {
      return <div style={{ height: '325px' }} />;
    }

    if ('xs' === width) {
      return <div style={{ height: '285px' }} />;
    }

    return <div style={{ height: '400px' }} />;
  }, [width]);

  const mapElement = useMemo(() => {
    if ('sm' === width) {
      return <div style={{ height: '325px' }} />;
    }

    if ('xs' === width) {
      return <div style={{ height: '285px' }} />;
    }

    return <div style={{ height: '400px' }} />;
  }, [width]);

  return (
    <>
      <MapCore
        googleMapURL={googleMapURL}
        loadingElement={loadingElement}
        containerElement={containerElement}
        mapElement={mapElement}
        filteredStudios={filteredStudios}
      />
    </>
  );
};

export default Map;
