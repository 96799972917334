import compose from 'app/hoc';

import { withActiveSubscription } from './useActiveSubscription';
import { withAuthentication } from './useAuthentication';
import { withClub } from './useClub';
import { withConnectedContact } from './useConnectedContact';
import { withCurrentStudioBoxAssignation } from './useCurrentStudioBoxAssignation';
import { withEntitiesState } from './useEntities';
import { withFileProofOfAddress } from './useFileProofOfAddress';
import { withStudioWithState } from './useStudioWithState';
import { withSecurityPolicies } from './useSecurityPolicies';
import { withIdentificators } from './useIdentificators';

export { default as useAccessTypeState } from './useAccessTypeState';
export { default as useActiveSubscription } from './useActiveSubscription';
export { default as useApiClient } from './useApiClient';
export { default as useAuthentication } from './useAuthentication';
export { default as useClub } from './useClub';
export { default as useConfig } from './useConfig';
export { default as useConnectedContact } from './useConnectedContact';
export { default as useCurrentStudioBoxAssignation } from './useCurrentStudioBoxAssignation';
export { default as useDenormalizedState } from './useDenormalizedState';
export { default as useEntityManager } from './useEntityManager';
export { default as useFileProofOfAddress } from './useFileProofOfAddress';
export { default as useIsAuthenticated } from './useIsAuthenticated';
export { default as usePayment } from './usePayment';
export { default as useSaleState } from './useSaleState';
export { default as useScopeState } from './useScopeState';
export { default as useStudioWithState } from './useStudioWithState';
export { default as useStudioAvailablePlaces } from './useStudioAvailablePlaces';
export { default as useWidth } from './useWidth';
export { default as useOffer } from './useOffer';
export { default as useStudioBoxes } from './useStudioBoxes';
export { default as useLiveData } from './useLiveData';
export { default as useIdentificators } from './useIdentificators';
export { default as useContactPreferences } from './useContactPreferences';
export { default as useContactTags } from './useContactTags';
export { default as useSecurityPolicies } from './useSecurityPolicies';
export { default as useOptins } from './useOptins';

const withHookProviders = compose(
  withAuthentication,
  withEntitiesState,
  withConnectedContact,
  withActiveSubscription,
  withCurrentStudioBoxAssignation,
  withClub,
  withStudioWithState,
  withFileProofOfAddress,
  withSecurityPolicies,
  withIdentificators
);

export default withHookProviders;
