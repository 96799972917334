import amber from '@material-ui/core/colors/amber';
import { SnackbarContent, makeStyles } from '@material-ui/core';
import React, { ComponentType } from 'react';
import { StudioType } from 'app/types';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText(amber[200]),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type CurrentWaitingListMessageProps = {
  studio: StudioType;
};

const CurrentWaitingListMessage: ComponentType<CurrentWaitingListMessageProps> = ({ studio }) => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          Vous êtes inscris en liste d’attente :&nbsp;<strong>{studio.name}</strong>
        </span>
      }
    />
  );
};

export default CurrentWaitingListMessage;
