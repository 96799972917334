import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { IconButton, AppBar, Toolbar, Box } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useWidth } from 'app/hooks';

export interface QrCodeDialogProps {
  open: boolean;
  onClose: () => void;
  qrcode: string;
}

function QrCodeDialog(props: QrCodeDialogProps) {
  const { onClose, open, qrcode } = props;
  const width = useWidth();

  return (
    <Dialog maxWidth={width} fullScreen onClose={onClose} aria-labelledby="qrcode-dialog-title" open={open}>
      <AppBar>
        <Toolbar>
          <Box display="flex" justifyContent={'space-between'} width={'100%'}>
            <DialogTitle id="qrcode-dialog-title">QR Code</DialogTitle>
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <Close />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <iframe title="QR Code" width="100%" height="100%" src={qrcode} />
    </Dialog>
  );
}

export default QrCodeDialog;
