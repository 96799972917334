import { Box, Paper, Typography } from '@material-ui/core';
import React, { ComponentType } from 'react';
import { StudioBoxType, StudioType } from 'app/types';
import Map from './Map';
import StudioBoxItem from './StudioBoxItem';

type StudioResumeForHomepageProps = {
  studio: StudioType;
  withInformationOnPlaces?: {
    selectedStudioBox?: StudioBoxType;
    studioBoxes?: StudioBoxType[];
  };
};

const StudioResumeForHomepage: ComponentType<StudioResumeForHomepageProps> = ({
  studio,
  withInformationOnPlaces,
}): JSX.Element => (
  <Paper elevation={1}>
    <Box py={1.5} px={3}>
      <Box textAlign="left">
        <Typography variant="caption" color="textSecondary">
          Mon abri
        </Typography>
      </Box>
      <Box my={2} minHeight="80px">
        <Box>
          <Typography variant="h6" component="div" align="center" gutterBottom>
            {studio.name}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography align="left" variant="body2" gutterBottom>
            {studio.streetAddress}
          </Typography>
          <Typography align="left" variant="body2" gutterBottom>
            {`${studio.postalCode} ${studio.addressLocality}`}
          </Typography>
        </Box>
      </Box>

      <Map item={studio} height={100} />

      {withInformationOnPlaces &&
        withInformationOnPlaces.studioBoxes &&
        0 !== withInformationOnPlaces.studioBoxes.length &&
        withInformationOnPlaces.selectedStudioBox && (
          <Box>
            <Typography align="left" variant="body2" gutterBottom>
              Ma place
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="top" justifyContent="space-between" mb={2}>
              {withInformationOnPlaces.studioBoxes.map((studioBox: StudioBoxType) => (
                <StudioBoxItem
                  key={studioBox['@id']}
                  selected={
                    withInformationOnPlaces.selectedStudioBox &&
                    withInformationOnPlaces.selectedStudioBox['@id'] === studioBox['@id']
                  }
                  item={studioBox}
                />
              ))}
            </Box>
          </Box>
        )}
    </Box>
  </Paper>
);

export default StudioResumeForHomepage;
