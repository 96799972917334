import { Button, SnackbarContent, Typography, makeStyles } from '@material-ui/core';
import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: '#fff',
    color: theme.palette.getContrastText('#fff'),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  number: {
    marginRight: theme.spacing(1),
  },
}));

type NumberOfSubscriptionInfoMessageProps = {
  numberOfElement: number;
};

const NumberOfSubscriptionInfoMessage: ComponentType<NumberOfSubscriptionInfoMessageProps> = ({ numberOfElement }) => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          <Typography variant="h3" className={classes.number}>
            {numberOfElement}
          </Typography>
          abri(s) disponible(s)
        </span>
      }
      action={
        <Button size="small" component={Link} to="/subscriptions">
          Je m'abonne
        </Button>
      }
    />
  );
};

export default NumberOfSubscriptionInfoMessage;
