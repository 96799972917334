import { Button, SnackbarContent, makeStyles } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import moment from 'moment';
import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  warningButton: {
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
}));

type TakeOffDateErrorMessageProps = {
  takeOffDate: Date;
};

const TakeOffDateErrorMessage: ComponentType<TakeOffDateErrorMessageProps> = ({ takeOffDate }) => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          <Error fontSize="small" className={classes.icon} />
          {`Votre réservation est arrivée à expiration et votre vélo a été retiré du box le ${moment(
            takeOffDate
          ).format('LL')}. Merci de contacter le support pour le récupérer.`}
        </span>
      }
      action={
        <Button className={classes.warningButton} size="small" component={Link} to="/contact">
          Contact
        </Button>
      }
    />
  );
};

export default TakeOffDateErrorMessage;
