import { useEffect, useMemo } from 'react';
import axios from 'axios';
import useAuthentication from './useAuthentication';
import useConfig from './useConfig';

const useApiClient = () => {
  const [, { getFreshAccessToken }] = useAuthentication();
  const apiConfig = useConfig((config) => config.api);

  const client = useMemo(() => {
    return axios.create();
  }, []);

  useEffect(() => {
    const interceptorIndex = client.interceptors.request.use(async (requestConfig) => {
      // eslint-disable-next-line no-process-env
      if (process.env.NODE_ENV === 'development' && requestConfig.method && apiConfig.withMoreLogs) {
        console.log(`[API] ${requestConfig.method.toUpperCase()} ${requestConfig.url}`);
      }

      const token = await getFreshAccessToken();

      return {
        ...requestConfig,
        baseURL: apiConfig.url,
        headers: { ...requestConfig.headers, Authorization: token && `Bearer ${token.access_token}` },
      };
    });

    return () => {
      client.interceptors.request.eject(interceptorIndex);
    };
  }, [apiConfig.url, client.interceptors.request, getFreshAccessToken, apiConfig.withMoreLogs]);

  useEffect(() => {
    const interceptorIndex = client.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        if (apiConfig.withMoreLogs) {
          console.log('networkError', {
            method: err.request._method,
            responseURL: err.request.responseURL,
            status: err.request.status,
            _response: err.request._response,
          });
        }

        return Promise.reject(err);
      }
    );

    return () => {
      client.interceptors.response.eject(interceptorIndex);
    };
  }, [client.interceptors.response, apiConfig.withMoreLogs]);

  return client;
};

export default useApiClient;
