import React, { ComponentType } from 'react';
import { useConnectedContact } from 'app/hooks';
import NotConnected from 'app/pages/Homepage/NotConnected';
import Connected from 'app/pages/Homepage/Connected';
import MainLoader from 'app/components/MainLoader';

const Homepage: ComponentType = () => {
  const [{ contact, contactLoaded }] = useConnectedContact();

  if (!contactLoaded) {
    return <MainLoader />;
  }

  return <div>{contact ? <Connected /> : <NotConnected />}</div>;
};

export default Homepage;
