import { useEffect, useMemo } from 'react';
import { denormalize, Schema } from 'normalizr';
import useEntities from './useEntities';
import { useState } from 'react';

const useDenormalize = <T = any>(input: any, inputSchema: Schema): T => {
  const entities = useEntities();
  const denormalizedResult = useMemo(() => denormalize(input, inputSchema, entities), [input, inputSchema, entities]);

  return denormalizedResult;
};

const useDenormalizedState = <T = any>(input: any, inputSchema: Schema) => {
  const [result, setResult] = useState(input);
  const denormalizedResult = useDenormalize<T>(result, inputSchema);

  // not really clean but necessary to avoir useless reload component when fetch an entity (prevent for a lot of infinite useEffect loop)
  const [finalResult, setFinalResult] = useState(denormalizedResult);
  useEffect(() => {
    if (JSON.stringify(finalResult) !== JSON.stringify(denormalizedResult)) {
      setFinalResult(() => denormalizedResult);
    }
  }, [denormalizedResult, finalResult]);

  return [finalResult, setResult] as [typeof denormalizedResult, typeof setResult];
};

export default useDenormalizedState;
