import qs from 'qs';

const redirectTo = (uri) =>
  new Promise(() => {
    document.location.replace(uri);
  });

let tokenPromise;

const fetchToken = async (url, body) => {
  const response = await fetch(url, { method: 'POST', body });
  if (response.status === 442) {
    throw new Error('Sending_authentication_sms');
  } else if (response.status === 429) {
    throw new Error('too_many_requests');
  } else if (!response.ok) {
    throw new Error('Authentication_failed');
  }

  return response.json();
};

const makeOauthClient = (clientToken, oauthConfig, redirectUri = document.location.href) => ({
  async getToken(formData) {
    try {
      tokenPromise = tokenPromise || fetchToken(`${oauthConfig.url}/${clientToken}/oauth/v2/token`, formData);

      const token = await tokenPromise;
      tokenPromise = null;
      return token;
    } catch (error) {
      // do nothing
      tokenPromise = null;
      throw error;
    }
  },

  getTokenFromAuthorizationCode(code) {
    const form = new URLSearchParams();

    form.append('grant_type', 'authorization_code');
    form.append('client_id', oauthConfig.clientId);
    form.append('client_secret', oauthConfig.clientSecret);
    form.append('code', code);
    form.append('redirect_uri', redirectUri);

    return this.getToken(form);
  },

  getTokenFromClientCredentials() {
    const form = new URLSearchParams();

    form.append('grant_type', 'client_credentials');
    form.append('client_id', oauthConfig.anonymousClientId);
    form.append('client_secret', oauthConfig.anonymousClientSecret);

    return this.getToken(form);
  },

  getTokenFromRefreshToken(refreshToken) {
    const form = new URLSearchParams();

    form.append('grant_type', 'refresh_token');
    form.append('client_id', oauthConfig.clientId);
    form.append('client_secret', oauthConfig.clientSecret);
    form.append('refresh_token', refreshToken);
    form.append('redirect_uri', redirectUri);

    return this.getToken(form);
  },

  getTokenFromPassword(email, password, code) {
    const form = new URLSearchParams();

    form.append('grant_type', 'password');
    form.append('username', email);
    form.append('password', password);
    form.append('client_id', oauthConfig.clientId);
    form.append('client_secret', oauthConfig.clientSecret);
    form.append('redirect_uri', redirectUri);

    if (code !== '') {
      form.append('code', code);
    }

    return this.getToken(form);
  },

  async gotoLogin(params = {}) {
    const queryString = qs.stringify({
      client_id: oauthConfig.clientId,
      response_type: 'code',
      ...params,
    });

    await redirectTo(`${oauthConfig.url}/${clientToken}/oauth/login?${queryString}`);
  },

  async gotoLogout(params = {}) {
    const queryString = qs.stringify({
      ...params,
    });

    await redirectTo(`${oauthConfig.url}/${clientToken}/oauth/logout?${queryString}`);
  },

  async gotoPasswordLost(params = {}) {
    const queryString = qs.stringify({
      ...params,
    });

    await redirectTo(`${oauthConfig.url}/${clientToken}/oauth/password-lost?${queryString}&response_type=code`);
  },
});

export default makeOauthClient;
