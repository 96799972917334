import moment from 'moment';
import 'moment/locale/fr';
import React, { Suspense, ComponentType, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { CandidacyWorkflowProvider, SubscribeWorkflowProvider, SubscriptionsWorkflowProvider } from 'app/contexts';
import { useIsAuthenticated, useConfig, useConnectedContact } from 'app/hooks'; // without this line it didn't work
import withProviders from 'app/providers';
import Layout from './Layout';
import { Helmet } from 'react-helmet';
import { initialize } from '@stadline/react-mtcaptcha';
import Homepage from 'app/pages/Homepage';
import NoMatch from '../pages/NoMatch';
import MainLoader from './MainLoader';
import MyAccount from 'app/pages/Account/MyAccount';
moment.locale('fr');

const routes = [
  {
    exact: true,
    path: '/',
    component: Homepage,
  },
  {
    exact: true,
    path: '/our-boxes',
    component: React.lazy(() => import('../pages/OurBoxes/Boxes')),
  },
  {
    exact: true,
    path: '/our-offers',
    component: React.lazy(() => import('../pages/static/Offers')),
  },
  {
    exact: true,
    path: '/our-rules',
    component: React.lazy(() => import('../pages/static/Rules')),
  },
  {
    exact: true,
    path: '/legal-notice',
    component: React.lazy(() => import('../pages/static/LegalNotice')),
  },
  {
    exact: true,
    path: '/our-service',
    component: React.lazy(() => import('../pages/static/Service')),
  },
  {
    exact: true,
    path: '/contact',
    component: React.lazy(() => import('../pages/Contact/Form')),
  },
  {
    exact: true,
    path: '/faq',
    component: React.lazy(() => import('../pages/static/FaqRatp')),
  },
];

const privateRoutes = [
  {
    exact: true,
    path: '/subscribe/confirmation',
    component: React.lazy(() => import('../pages/Subscribe/Confirmation')),
  },
  {
    exact: true,
    path: '/subscribe/proof-of-address',
    component: React.lazy(() => import('../pages/Subscribe/ProofOfAddress')),
  },
  {
    exact: true,
    path: '/candidacies',
    component: React.lazy(() => import('../pages/Candidaties/CandidacyReservation')),
  },
  {
    exact: true,
    path: '/waiting-lists',
    component: React.lazy(() => import('../pages/WaitingLists/WaitingListsReservation')),
  },
  {
    exact: true,
    path: '/subscriptions',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/SubscriptionList')),
  },
  {
    exact: true,
    path: '/subscriptions/badges/navigo',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/AddNavigoCard')),
  },
  {
    exact: true,
    path: '/subscriptions/badges/check',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/IdpnCheck')),
  },
  {
    exact: true,
    path: '/subscriptions/reservation',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/SubscriptionReservation')),
  },
  {
    exact: true,
    path: '/subscriptions/resume',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/Resume')),
  },
  {
    exact: true,
    path: '/subscriptions/payment-by-check',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/PaymentByCheck')),
  },
  {
    exact: true,
    path: '/subscriptions/confirmation/:invoiceId',
    component: React.lazy(() => import('../pages/SubscriptionsSaleProcess/Confirmation')),
  },
  {
    exact: true,
    path: '/reservation',
    component: React.lazy(() => import('../pages/ReservationProcess/StudioList')),
  },
  {
    exact: true,
    path: '/my-account',
    component: MyAccount,
  },
  {
    exact: true,
    path: '/my-reservation',
    component: React.lazy(() => import('../pages/Reservations/MyReservation')),
  },
  {
    exact: true,
    path: '/my-account/past-subscriptions',
    component: React.lazy(() => import('../pages/Account/PastSubscriptions')),
  },
  {
    exact: true,
    path: '/my-account/user-infos',
    component: React.lazy(() => import('../pages/Account/UserInfos')),
  },
  {
    exact: true,
    path: '/my-account/consents',
    component: React.lazy(() => import('../pages/Account/Consents')),
  },
  {
    exact: true,
    path: '/my-account/invoice-history',
    component: React.lazy(() => import('../pages/Account/InvoiceHistory')),
  },
  {
    exact: true,
    path: '/my-account/entry-history',
    component: React.lazy(() => import('../pages/Account/EntryHistory')),
  },
  {
    exact: true,
    path: '/my-account/my-waiting-list',
    component: React.lazy(() => import('../pages/Account/MyWaitingList')),
  },
  {
    exact: true,
    path: '/my-reservation/penalty',
    component: React.lazy(() => import('../pages/PenaltySaleProcess/PenaltySalePayment')),
  },
  {
    exact: true,
    path: '/my-reservation/penalty/:invoiceId',
    component: React.lazy(() => import('../pages/PenaltySaleProcess/Confirmation')),
  },
  {
    exact: true,
    path: '/my-account/my-badge',
    component: React.lazy(() => import('../pages/Badges/MyBadges')),
  },
  {
    exact: true,
    path: '/my-account/my-badges/add-badge',
    component: React.lazy(() => import('../pages/Badges/AddCard')),
  },
  {
    exact: true,
    path: '/subscriptions/extensions',
    component: React.lazy(() => import('../pages/SubscriptionExtensionProcess/SubscriptionExtensionList')),
  },
  {
    exact: true,
    path: '/subscriptions/extensions/resume',
    component: React.lazy(() => import('../pages/SubscriptionExtensionProcess/Resume')),
  },
  {
    exact: true,
    path: '/subscriptions/extensions/payment-by-check',
    component: React.lazy(() => import('../pages/SubscriptionExtensionProcess/PaymentByCheck')),
  },
  {
    exact: true,
    path: '/subscriptions/extensions/confirmation/:invoiceId',
    component: React.lazy(() => import('../pages/SubscriptionExtensionProcess/Confirmation')),
  },
  {
    exact: true,
    path: '/change-password',
    component: React.lazy(() => import('../pages/ChangePassword/Form')),
  },
  {
    exact: true,
    path: '/change-password/confirmation',
    component: React.lazy(() => import('../pages/ChangePassword/Confirmation')),
  },
];

const unauthenticateRoutes = [
  {
    exact: true,
    path: '/login',
    component: React.lazy(() => import('../pages/Login')),
  },
  {
    exact: true,
    path: '/lost-password',
    component: React.lazy(() => import('../pages/LostPassword/Form')),
  },
  {
    exact: true,
    path: '/lost-password/confirmation',
    component: React.lazy(() => import('../pages/LostPassword/Confirmation')),
  },
  {
    exact: true,
    path: '/reset-password',
    component: React.lazy(() => import('../pages/ResetPassword/Form')),
  },
  {
    exact: true,
    path: '/reset-password/confirmation',
    component: React.lazy(() => import('../pages/ResetPassword/Confirmation')),
  },
  {
    exact: true,
    path: '/subscribe',
    component: React.lazy(() => import('../pages/Subscribe/Form')),
  },
];

const checkForcedPasswordChangeRoute: any = (component: any, pathname: string, isPasswordChangeRequired: boolean) => {
  if (isPasswordChangeRequired && pathname !== '/change-password') {
    return () => <Redirect to={{ pathname: '/change-password', state: { isForced: true } }} />;
  }

  return component;
};

const CommonRoute: ComponentType<any> = ({ component, ...options }) => {
  const [{ contactUser, contactLoaded }] = useConnectedContact();
  const isAuthenticated = useIsAuthenticated();
  const { location } = useReactRouter();

  const finalComponent = useMemo(() => {
    if (isAuthenticated) {
      if (contactLoaded) {
        return checkForcedPasswordChangeRoute(component, location.pathname, contactUser?.isPasswordChangeRequired);
      }
    }

    return component;
  }, [isAuthenticated, component, contactLoaded, location.pathname, contactUser]);

  return <Route {...options} component={finalComponent} />;
};

const PrivateRoute: ComponentType<any> = ({ component, ...options }) => {
  const { location } = useReactRouter();
  const [{ contact, contactUser, contactLoaded }] = useConnectedContact();

  const finalComponent = useMemo(() => {
    if (contactLoaded && !contact) {
      return () => <Redirect to="/login" />;
    } else {
      if (contactLoaded) {
        return checkForcedPasswordChangeRoute(component, location.pathname, contactUser.isPasswordChangeRequired);
      }
    }

    return component;
  }, [contactLoaded, contact, component, location.pathname, contactUser]);

  return <Route {...options} component={finalComponent} />;
};

const UnauthenticateRoute: ComponentType<any> = ({ component, ...options }) => {
  const [{ contact, contactLoaded }] = useConnectedContact();
  const finalComponent = !(contactLoaded && contact) ? component : () => <Redirect to="/" />;

  return <Route {...options} component={finalComponent} />;
};

const ScrollToTop = () => {
  const { location } = useReactRouter();

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  return null;
};

const App: React.ComponentType = () => {
  const { favicon, title } = useConfig((config) => config.theme);
  const mtcaptcha = useConfig((config) => config.mtcaptcha);

  useEffect(() => {
    initialize({
      sitekey: mtcaptcha.siteKey,
      lang: 'fr',
    });
  }, [mtcaptcha.siteKey]);

  return (
    <div style={{ minHeight: '50vh' }}>
      <Helmet>
        {title && <title>{title}</title>}
        {favicon && <link rel="shortcut icon" href={favicon} />}
      </Helmet>
      <Router>
        <ScrollToTop />
        <SubscribeWorkflowProvider>
          <CandidacyWorkflowProvider>
            <Suspense fallback={<MainLoader />}>
              <Layout>
                <Suspense fallback={<MainLoader />}>
                  <Switch>
                    {routes.map((routeProps, routeIndex) => (
                      <CommonRoute key={routeIndex} {...routeProps} />
                    ))}
                    {unauthenticateRoutes.map((routeProps, routeIndex) => (
                      <UnauthenticateRoute key={routeIndex} {...routeProps} />
                    ))}
                    <SubscriptionsWorkflowProvider>
                      {privateRoutes.map((routeProps, routeIndex) => (
                        <PrivateRoute key={routeIndex} {...routeProps} />
                      ))}
                    </SubscriptionsWorkflowProvider>
                    <Route component={NoMatch} />
                  </Switch>
                </Suspense>
              </Layout>
            </Suspense>
          </CandidacyWorkflowProvider>
        </SubscribeWorkflowProvider>
      </Router>
    </div>
  );
};

export default withProviders(App);
