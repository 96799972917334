import { useCallback, useEffect, useState } from 'react';
import { useDenormalizedState, useEntityManager } from './index';
import { StudioPublicType } from '../types';
import { createUnionSchema } from '../schemas';

const useLiveData = () => {
  const StudioPublicSchema = createUnionSchema(['studiosLive']);

  const [{ fetchJsonList }, endpoints] = useEntityManager();
  const [isLoaded, setIsLoaded] = useState(false);
  const [studios, setStudios] = useDenormalizedState<StudioPublicType[]>([], [StudioPublicSchema]);

  const fetch = useCallback(async () => {
    try {
      const data = await fetchJsonList(endpoints.STUDIOS_PUBLIC, {});
      setStudios(data.result);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
    }
  }, [endpoints.STUDIOS_PUBLIC, fetchJsonList, setStudios]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const actions = {
    refresh: fetch,
  };

  const data = {
    liveStudioData: studios,
    isLoaded,
  };

  return [data, actions] as [typeof data, typeof actions];
};

export default useLiveData;
