import { useCallback, useEffect, useState } from 'react';
import { useEntityManager } from './index';

const useStudioAvailablePlaces = (studioId: string) => {
  const [{ fetchEntity }] = useEntityManager();

  const [remainingPlaces, setRemainingPlaces] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchRemainingPlaces = useCallback(async () => {
    try {
      const data: { result: any } = await fetchEntity(`${studioId}/remaining_places`);
      setRemainingPlaces(Number(data.result && data.result.remainingPlaces));
      setIsLoaded(true);
    } catch (error) {
      // nothing to do
    }
  }, [fetchEntity, setRemainingPlaces, studioId]);

  useEffect(() => {
    fetchRemainingPlaces();
  }, [fetchRemainingPlaces]);

  const data = {
    remainingPlaces,
    isLoaded,
  };

  const actions = {
    refresh: fetchRemainingPlaces,
  };

  return [data, actions] as [typeof data, typeof actions];
};

export default useStudioAvailablePlaces;
