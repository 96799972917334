import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDenormalizedState, useEntityManager } from './index';
import { ProductType } from '../types';
import { createUnionSchema, createSchema } from '../schemas';

const productSchema = createUnionSchema([
  'products',
  {
    offers: [createSchema('offers')],
    productBehaviors: [createSchema('productBehaviors')],
  },
]);

const useOffer = () => {
  const [products, setProducts] = useDenormalizedState<ProductType[]>([], [productSchema]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [{ fetchEntityList }, endpoints] = useEntityManager();

  const productsWithOffer = useMemo(() => {
    return products.filter((item) => {
      return item.offers.length > 0;
    });
  }, [products]);

  const fetch = useCallback(async () => {
    try {
      const data = await fetchEntityList(endpoints.PRODUCTS, {
        params: {
          type: ['subscription', 'subscription_studio', 'subscription_studio_box'],
          visible: 1,
        },
      });
      setProducts(data.result);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
    }
  }, [endpoints.PRODUCTS, fetchEntityList, setProducts]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const actions = {
    refresh: fetch,
  };

  const data = {
    products,
    productsWithOffer,
    isLoaded,
  };

  return [data, actions] as [typeof data, typeof actions];
};

export default useOffer;
