import moment from 'moment';

export const convertDatesToDiff: (from: Date, to: Date) => string = (from, to) => {
  let diff: moment.Duration | number = moment.duration(moment(to).diff(moment(from)));
  if (diff.asYears() > 1) {
    diff = Math.floor(diff.asYears());
    return `${diff} an(s)`;
  } else if (diff.asMonths() > 1) {
    diff = Math.floor(diff.asMonths());
    return `${diff} mois`;
  }
  diff = Math.floor(diff.asDays());
  return `${diff} jour(s)`;
};
