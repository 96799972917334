import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type PropTypes = {
  component?: React.ElementType;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

const useStyles = makeStyles<Theme, { size?: string }>((theme) => ({
  root: {
    gridColumn: (props) => props.size || '1 / -1',
  },
}));

const GridItem: React.ComponentType<PropTypes & React.HTMLAttributes<HTMLElement>> = ({
  component: Component = 'div',
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}) => {
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.up('xs')),
    sm: useMediaQuery(theme.breakpoints.up('sm')),
    md: useMediaQuery(theme.breakpoints.up('md')),
    lg: useMediaQuery(theme.breakpoints.up('lg')),
    xl: useMediaQuery(theme.breakpoints.up('xl')),
  };

  let size;
  if (xs && matches.xs) size = `auto / span ${xs}`;
  if (sm && matches.sm) size = `auto / span ${sm}`;
  if (md && matches.md) size = `auto / span ${md}`;
  if (lg && matches.lg) size = `auto / span ${lg}`;
  if (xl && matches.xl) size = `auto / span ${xl}`;

  const classes = [useStyles({ size }).root];
  if (props.className) classes.push(props.className);

  return <Component {...props} className={classes.join(' ')} />;
};

export default GridItem;
