import { Box, FormControl, Select, MenuItem, Typography } from '@material-ui/core';
import React, { ComponentType, useState } from 'react';
import { StudioType, StudioBoxType } from 'app/types';
import StudioResumeForHomepage from './StudioResumeForHomepage';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
}));

type StudioResumeForHomepageProps = {
  studios: StudioType[];
  withInformationOnPlaces?: {
    selectedStudioBox?: StudioBoxType;
    studioBoxes?: StudioBoxType[];
  };
};

const MultiStudioResumeForHomepage: ComponentType<StudioResumeForHomepageProps> = ({
  studios,
  withInformationOnPlaces,
}): JSX.Element => {
  const classes = useStyles();
  const [selectedStudio, setSelectedStudio] = useState<StudioType>(studios[0]);

  const handleStudioChange = (event: any) => {
    const newSelected = studios.find((item) => item.name === event.target.value);

    if (newSelected === undefined) {
      return;
    }

    setSelectedStudio(newSelected);
  };

  return (
    <>
      <Box py={1.5} px={3} display="flex" flexDirection="row">
        <Box textAlign="left" pb={0.5} width={'50%'}>
          <Typography variant="caption" color="textSecondary">
            les {studios.length} abris auxquels vous avez accès
          </Typography>
        </Box>
        <Box textAlign="left" pb={0.5} pl={2} width={'50%'}>
          <FormControl className={classes.formControl}>
            <Select value={selectedStudio.name} onChange={handleStudioChange}>
              {studios.map((studio) => (
                <MenuItem value={studio.name} key={studio['@id']}>
                  {studio.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box>
        <StudioResumeForHomepage studio={selectedStudio} withInformationOnPlaces={withInformationOnPlaces} />
      </Box>
    </>
  );
};

export default MultiStudioResumeForHomepage;
