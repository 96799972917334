import { useCallback, useMemo, useState } from 'react';
import createPersistedState from 'use-persisted-state';
import jwtDecode from 'jwt-decode';
import useConfig from './useConfig';
import makeOauthClient from './services/makeOauthClient';
import createProvider from './createProvider';
import Cookies from 'js-cookie';

const emptyAuthentication = {
  grantType: null,
  value: undefined,
};

type GrantType = 'authorization_code' | 'client_credentials' | 'refresh_token' | 'password';
type TokenType = {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  scope?: string;
  token_type?: 'bearer';
};
type AuthenticationType = { grantType: GrantType; value: TokenType } | typeof emptyAuthentication;

type JWTTokenType = { exp: number; iat: string; jti: string } & {
  clientToken: string;
  club?: string;
  email: string;
  roles: string[];
  targetId?: string;
};

export const getIsAccessTokenValid = (accessToken?: string, secondsBeforeRefresh = 0) => {
  if (typeof accessToken !== 'string') return false;

  const jwtContent = jwtDecode<{ exp: number; iat: string; email: string }>(accessToken);

  const now = Math.floor(Date.now() / 1000);
  const remainingLifetime = jwtContent.exp - now - secondsBeforeRefresh;
  return remainingLifetime > 0;
};

const useAuthState = createPersistedState('auth2', {
  getItem: (k: any): any => {
    return Cookies.get(k);
  },
  setItem: (k: string, v) => {
    const j = JSON.parse(v);
    if (j && j?.grantType === 'client_credentials') {
      Cookies.remove(k);
      return;
    }

    Cookies.set(k, v, {
      expires: 31,
      sameSite: 'strict',
      // eslint-disable-next-line
      secure: !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
    });
  },
});

const useAuthenticationState = () => {
  const clientToken = useConfig((config) => config.clientToken);
  const oauthConfig = useConfig((config) => config.oauth);
  const oauthClient = makeOauthClient(clientToken, oauthConfig);
  const [authentication, setAuthentication] = useAuthState<AuthenticationType>(emptyAuthentication);
  const [nonPersistantRefreshToken, setNonPersistantRefreshToken] = useState<string | undefined>();

  // handlers
  const logout = useCallback(() => {
    setAuthentication(emptyAuthentication);
    setNonPersistantRefreshToken(undefined);
  }, [setAuthentication]);

  const authenticate = useCallback(
    async (email: string, password: string, code: string, remember: boolean) => {
      // retrieve access token, using provided code
      try {
        const oauthValue = await oauthClient.getTokenFromPassword(email, password, code);
        const accessToken = oauthValue && oauthValue.access_token;
        const jwtContent = jwtDecode<JWTTokenType>(accessToken);
        if (jwtContent.targetId && !/\/users\//.test(jwtContent.targetId)) {
          if (!remember) {
            setNonPersistantRefreshToken(oauthValue.refresh_token);
            delete oauthValue.refresh_token;
          } else {
            setNonPersistantRefreshToken(undefined);
          }

          setAuthentication({
            grantType: 'password',
            value: oauthValue,
          });
        } else {
          setAuthentication(emptyAuthentication);
          throw new Error('Vous ne pouvez pas vous connecter avec votre compte administrateur.');
        }
      } catch (error) {
        console.log('exit failure auth');

        // failure, try again
        if ((error as any).message === 'Authentication_failed') {
          throw new Error('Connexion échouée. Veuillez vérifier vos identifiants.');
        } else throw error;
      }
    },
    [oauthClient, setAuthentication]
  );

  const getFreshAccessToken = useCallback(async (): Promise<TokenType | undefined> => {
    // seconds to remove from lifetime, to ensure a valid session everytime
    const refreshToken = (authentication.value && authentication.value.refresh_token) || nonPersistantRefreshToken;
    const isAccessTokenValid = authentication.value && getIsAccessTokenValid(authentication.value.access_token);

    // token is valid : return it without calc
    if (isAccessTokenValid) {
      return authentication.value;
    }

    try {
      if (refreshToken) {
        const tokenValue = await oauthClient.getTokenFromRefreshToken(refreshToken);
        if (nonPersistantRefreshToken) {
          setNonPersistantRefreshToken(tokenValue.refresh_token);
          delete tokenValue.refresh_token;
        }
        console.log('Successfully fetch fresh auth token');
        setAuthentication({ grantType: authentication.grantType || 'refresh_token', value: tokenValue });
        return tokenValue;
      }
    } catch (error) {
      console.log('Auth token cannot be fetch ... user disconnected');
    }

    // reset client credential credentials
    const tokenValue = await oauthClient.getTokenFromClientCredentials();
    setNonPersistantRefreshToken(undefined);
    setAuthentication({ grantType: 'client_credentials', value: tokenValue });
    return tokenValue;
  }, [authentication.grantType, authentication.value, nonPersistantRefreshToken, oauthClient, setAuthentication]);

  // return hook values
  const actions = {
    authenticate,
    getFreshAccessToken,
    setToken: setAuthentication,
    logout,
  };

  const targetId = useMemo(() => {
    const accessToken = (authentication && authentication.value && authentication.value.access_token) || null;
    if (!accessToken) return null;
    const jwtContent = jwtDecode<JWTTokenType>(accessToken);

    return jwtContent.targetId;
  }, [authentication]);

  type ReturnType = [typeof authentication.value, typeof actions, typeof authentication.grantType, typeof targetId];
  return [authentication.value, actions, authentication.grantType, targetId] as ReturnType;
};

const [withAuthentication, useAuthentication] = createProvider(useAuthenticationState);

export { withAuthentication };

export default useAuthentication;
