import { Box, Chip, Link, Typography } from '@material-ui/core';
import { lightGreen, deepOrange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { ComponentType, useState } from 'react';
import { StudioType } from 'app/types';
import SimpleModal from 'app/components/common/Modal';
import Map from './Map';
import { useStudioAvailablePlaces, useClub, useConfig } from 'app/hooks';
import StudioItemSummarySubscribeButton from './StudioItemSummarySubscribeButton';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: '5px',
    backgroundColor: lightGreen[500],
    color: '#fff',
  },
  chipError: {
    backgroundColor: deepOrange[500],
  },
  chipNotSecured: {
    backgroundColor: '#0098d7',
  },
  chipNotSecuredSoon: {
    backgroundColor: '#0bb9e7',
  },
}));

type StudioItemSummaryProps = {
  item: StudioType;
  withActiveWaitingList?: boolean;
  withButtonByState?: boolean;
  withInformationOnPlaces?: {
    boxesWithChildPlace: number;
    remainingBoxes: number;
    isBoxesWithChildPlace: boolean;
    isBoxesWithElectricalOutlet: boolean;
    boxesWithElectricalOutlet: number;
  };
  withModalMap?: boolean;
};

const StudioItemSummary: ComponentType<StudioItemSummaryProps> = ({
  item,
  item: { name, streetAddress, postalCode, addressLocality },
  withActiveWaitingList,
  withButtonByState,
  withInformationOnPlaces,
  withModalMap,
}): JSX.Element => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const [{ remainingPlaces }] = useStudioAvailablePlaces(item['@id']);
  const club = useClub();
  const customWording = useConfig((config) => config.customWording);

  return (
    <Box display="flex" flexDirection="row" alignItems="top" mt={1}>
      <Box width="150px" textAlign="center">
        <Typography variant="h6" component="div" align="center" gutterBottom>
          {name}
        </Typography>
        {item.type !== 'all' && (
          <>
            {undefined !== item.state && 'full' === item.state && (
              <Chip size="small" label={'Complet'} className={classNames(classes.chip, classes.chipError)} />
            )}
            {undefined !== item.state && ['can_candidacy', 'can_subscribe'].includes(item.state) && (
              <Chip size="small" label={'disponible'} className={classes.chip} />
            )}
            {((undefined !== item.state && ['can_waiting_list'].includes(item.state)) ||
              (undefined !== item.state && ['none'].includes(item.state) && item.secured) ||
              undefined === item.state) && (
              <Chip size="small" label={'indisponible'} className={classNames(classes.chip, classes.chipError)} />
            )}
            {undefined !== item.state && 'soon' === item.state && item.secured && (
              <Chip size="small" label={'bientôt disponible'} className={classNames(classes.chip, classes.chipError)} />
            )}
            {undefined !== item.state && ['none'].includes(item.state) && !item.secured && (
              <Chip size="small" label={'Disponible'} className={classNames(classes.chip, classes.chipNotSecured)} />
            )}
            {undefined !== item.state && ['soon'].includes(item.state) && !item.secured && (
              <Chip label={'Bientôt disponible'} className={classNames(classes.chip, classes.chipNotSecuredSoon)} />
            )}
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column" ml={3}>
        {item.addressVisible && (
          <>
            <Typography align="left" variant="body2" gutterBottom>
              {streetAddress}
            </Typography>
            <Typography align="left" variant="body2" gutterBottom>
              {`${postalCode} ${addressLocality}`}
            </Typography>
          </>
        )}
        {item.type === 'collective' && (
          <Typography align="left" variant="body2" gutterBottom>
            {(customWording && customWording.studioCapacity) || "Capacité de l'abri"} : {item.capacity} places
          </Typography>
        )}

        {withInformationOnPlaces &&
          item.secured &&
          item.type !== 'collective' &&
          item.type === 'all' &&
          club &&
          club.xClubMaxSubscription === null && (
            <Typography align="left" variant="body2" gutterBottom>
              {0 === withInformationOnPlaces.remainingBoxes ? (
                'can_candidacy' !== item.state && 'Aucune place disponible'
              ) : (
                <>
                  <strong>{withInformationOnPlaces.remainingBoxes}</strong> place(s) disponible(s){' '}
                  {(withInformationOnPlaces.isBoxesWithChildPlace ||
                    withInformationOnPlaces.isBoxesWithElectricalOutlet) &&
                    'dont '}
                  {withInformationOnPlaces.isBoxesWithChildPlace && (
                    <>
                      <strong>{withInformationOnPlaces.boxesWithChildPlace}</strong> place(s) siège enfant
                    </>
                  )}
                  {withInformationOnPlaces.isBoxesWithChildPlace &&
                    withInformationOnPlaces.isBoxesWithElectricalOutlet && <> et </>}
                  {withInformationOnPlaces.isBoxesWithElectricalOutlet && (
                    <>
                      <strong>{withInformationOnPlaces.boxesWithElectricalOutlet}</strong> place(s) avec prise
                      électrique
                    </>
                  )}
                </>
              )}
            </Typography>
          )}
        {item.type === 'all' && club && club.xClubMaxSubscription !== null && (
          <Typography align="left" variant="body2" gutterBottom>
            capacité : <strong>{item.capacity}</strong> place(s)
          </Typography>
        )}
        {item.type === 'collective' && item.secured && (
          <Typography align="left" variant="body2" gutterBottom>
            {0 === remainingPlaces ? (
              'Aucun abonnement disponible'
            ) : (
              <>
                <strong>{remainingPlaces}</strong> abonnement(s) disponible(s)
              </>
            )}
          </Typography>
        )}
        {withActiveWaitingList && 'can_waiting_list' === item.state && item.secured && (
          <Typography align="left" variant="body2" gutterBottom>
            <strong>Liste d'attente disponible</strong>
          </Typography>
        )}
        {withModalMap && <Link onClick={() => setModalOpen(!modalOpen)}>Voir sur la carte</Link>}
        {withButtonByState &&
          item.secured &&
          undefined !== item.state &&
          ['can_subscribe', 'can_waiting_list', 'can_candidacy'].includes(item.state) && (
            <StudioItemSummarySubscribeButton item={item} />
          )}
        {withInformationOnPlaces && (
          <SimpleModal handleClose={handleClose} open={modalOpen}>
            <Box pb={1}>
              <StudioItemSummary item={item} withInformationOnPlaces={withInformationOnPlaces} />
            </Box>
            <Box>
              <Map item={item} />
            </Box>
          </SimpleModal>
        )}
      </Box>
    </Box>
  );
};

export default StudioItemSummary;
