import { useCallback, useEffect, useState } from 'react';
import { useDenormalizedState, useEntityManager } from './index';
import { StudioBoxType } from '../types';
import { createUnionSchema } from '../schemas';

const studioBoxSchema = createUnionSchema(['studioBoxes']);

const useStudioBoxes = (studioId: string) => {
  const [{ fetchEntity, fetchEntityList }, endpoints] = useEntityManager();

  const [boxesWithChildPlace, setBoxesWithChildPlace] = useState<number>(0);
  const [isBoxesWithChildPlace, setIsBoxesWithChildPlace] = useState(false);
  const [boxesWithElectricalOutlet, setBoxesWithElectricalOutlet] = useState<number>(0);
  const [isBoxesWithElectricalOutlet, setIsBoxesWithElectricalOutlet] = useState(false);
  const [studioBoxes, setStudioBoxesResult] = useDenormalizedState<StudioBoxType[]>([], [studioBoxSchema]);
  const [remainingBoxes, setRemainingBoxes] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchStudioBoxes = useCallback(async () => {
    try {
      const data = await fetchEntityList(endpoints.STUDIO_BOXES, {
        params: {
          studio: studioId,
        },
      });
      setStudioBoxesResult(data.result);
    } catch (error) {
      // nothing to do
    }
  }, [endpoints.STUDIO_BOXES, fetchEntityList, setStudioBoxesResult, studioId]);

  useEffect(() => {
    fetchStudioBoxes();
  }, [fetchStudioBoxes]);

  useEffect(() => {
    setIsBoxesWithChildPlace(
      studioBoxes.filter((studioBox: StudioBoxType) => studioBox.boxOptions.includes('child_seat')).length > 0
    );

    setBoxesWithChildPlace(
      studioBoxes.filter((studioBox: StudioBoxType) => studioBox.free && studioBox.boxOptions.includes('child_seat'))
        .length
    );
  }, [setBoxesWithChildPlace, setIsBoxesWithChildPlace, studioBoxes]);

  useEffect(() => {
    setIsBoxesWithElectricalOutlet(
      studioBoxes.filter((studioBox: StudioBoxType) => studioBox.boxOptions.includes('electrical_outlet')).length > 0
    );

    setBoxesWithElectricalOutlet(
      studioBoxes.filter(
        (studioBox: StudioBoxType) => studioBox.free && studioBox.boxOptions.includes('electrical_outlet')
      ).length
    );
  }, [setBoxesWithElectricalOutlet, setIsBoxesWithElectricalOutlet, studioBoxes]);

  useEffect(() => {
    async function getRemainingBoxes() {
      try {
        const data: { result: any } = await fetchEntity(`${studioId}/remaining_boxes`);
        setRemainingBoxes(Number(data.result && data.result.remaining_boxes));
        setIsLoaded(true);
      } catch (error) {
        // nothing to do
      }
    }
    getRemainingBoxes();
  }, [fetchEntity, studioId]);

  const makeRemainingBoxesDisplay = () => {
    let display = remainingBoxes + ' place(s) disponible(s)';

    if (isBoxesWithChildPlace || isBoxesWithElectricalOutlet) {
      display += ' dont ';

      if (isBoxesWithChildPlace) {
        display += boxesWithChildPlace + ' place(s) siège enfant';
      }

      if (isBoxesWithElectricalOutlet) {
        if (isBoxesWithChildPlace) {
          display += ' et ';
        }

        display += boxesWithElectricalOutlet + ' place(s) avec prise électrique';
      }
    }

    return display;
  };

  const data = {
    studioBoxes,
    isBoxesWithChildPlace,
    boxesWithChildPlace,
    isBoxesWithElectricalOutlet,
    boxesWithElectricalOutlet,
    remainingBoxes,
    isLoaded,
  };

  const actions = {
    refresh: fetchStudioBoxes,
    getDefaultDisplay: makeRemainingBoxesDisplay,
  };

  return [data, actions] as [typeof data, typeof actions];
};

export default useStudioBoxes;
