import { useCallback, useEffect, useState } from 'react';
import { useAuthentication, useEntityManager, useDenormalizedState } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { ContactUserType, ContactType } from 'app/types/index';
import createProvider from './createProvider';

const contactUserSchema = createUnionSchema(['contactUsers']);
const contactSchema = createUnionSchema(['contacts']);

const useConnectedContact = () => {
  /**
   * HOOKS
   */
  const [, , , targetId] = useAuthentication();
  const [{ fetchEntity }] = useEntityManager();

  /**
   * STATES
   */
  const [contactUser, setContactUserResult] = useDenormalizedState<ContactUserType>(undefined, contactUserSchema);
  const [contact, setContactResult] = useDenormalizedState<ContactType>(undefined, contactSchema);
  const [contactLoaded, setContactLoaded] = useState<boolean>(false);

  /**
   * HANDLER
   */
  const refreshContact = useCallback(async () => {
    setContactLoaded(false);
    try {
      const contactUserData = await fetchEntity(targetId);
      setContactUserResult(contactUserData.result);
      const contactUser = contactUserData.response.data;

      const contactData = await fetchEntity(contactUser.contactId);
      setContactResult(contactData.result);
    } catch (error) {
      console.log('unable to refresh contact');
      throw error;
    } finally {
      setContactLoaded(true);
    }
  }, [fetchEntity, setContactResult, setContactUserResult, targetId]);

  /**
   * EFFECTS
   */
  // EFFECTS
  // Token empty : set undefined
  useEffect(() => {
    if (!targetId) {
      setContactResult(undefined);
      setContactUserResult(undefined);
      setContactLoaded(true);
    }
  }, [setContactResult, setContactUserResult, targetId]);

  // autoload data if not already loaded
  useEffect(() => {
    if (contact && contactUser) {
      return;
    }
    refreshContact();
  }, [contact, contactUser, refreshContact, targetId]);

  /**
   * RETURN
   */
  const values = {
    contact,
    contactUser,
    contactLoaded,
  };

  const actions = {
    refreshContact,
  };

  type ReturnType = [typeof values, typeof actions];
  return [values, actions] as ReturnType;
};

const [withConnectedContact, useProvidedConnectedContact] = createProvider(useConnectedContact);

export { withConnectedContact };

export default useProvidedConnectedContact;
