/**
 * CandidacyWorkflowContext.
 *
 * @description Manage candidacy workflow.
 */

import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDenormalizedState, useEntityManager, useIsAuthenticated } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { CandidacyType } from 'app/types';

type CandidacyWorkflow = {
  currentCandidacy?: CandidacyType;
  fetchCandidacies: () => void;
  isLoadedCurrentCandidacy: boolean;
};

type CandidacyWorkflowProps = {
  children: ReactNode;
};

const initialCandidacyWorkflowContext: CandidacyWorkflow = {
  fetchCandidacies: () => {
    // nothing to do
  },
  isLoadedCurrentCandidacy: false,
};

export const CandidacyWorkflowContext = createContext<CandidacyWorkflow>(initialCandidacyWorkflowContext);
CandidacyWorkflowContext.displayName = 'CandidacyWorkflowContext';

const candidaciesSchema = createUnionSchema(['candidacies']);
export const CandidacyWorkflowProvider = ({ children }: CandidacyWorkflowProps): JSX.Element => {
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const isAuthenticated = useIsAuthenticated();

  // Fetch candidacies
  const [isLoadedCurrentCandidacy, setLoaded] = useState(false);
  const [currentCandidacy, setCurrentCandidacy] = useState<CandidacyType>();
  const [candidacies, setCandidacies] = useDenormalizedState<CandidacyType[]>([], [candidaciesSchema]);
  const fetchCandidacies = useCallback(async () => {
    try {
      setLoaded(false);
      const data = await fetchEntityList(endpoints.CANDIDACIES);
      setCandidacies(data.result);
      setLoaded(true);
    } catch (error) {
      // nothing to do
    }
  }, [endpoints.CANDIDACIES, fetchEntityList, setCandidacies, setLoaded]);

  useEffect(() => {
    if (isAuthenticated && !isLoadedCurrentCandidacy) fetchCandidacies();
  }, [fetchCandidacies, isAuthenticated, isLoadedCurrentCandidacy]);
  useEffect(() => {
    if (candidacies && 0 !== candidacies.length) {
      setCurrentCandidacy(candidacies[0]);
    }
  }, [candidacies, setCurrentCandidacy]);

  return (
    <CandidacyWorkflowContext.Provider
      value={{
        currentCandidacy,
        fetchCandidacies,
        isLoadedCurrentCandidacy,
      }}
    >
      {children}
    </CandidacyWorkflowContext.Provider>
  );
};
