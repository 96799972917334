import React, { ComponentType, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Button, Grid as MaterialGrid } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import { LocalOfferTwoTone, HomeTwoTone, LocalGroceryStoreTwoTone } from '@material-ui/icons';
import { useConfig, useWidth } from 'app/hooks';
import MenuButton from 'app/components/MenuButton';
import Grid from 'app/components/Grid';
import Homepage2 from 'app/assets/stationvelivert.jpg';
import Homepage1 from 'app/assets/abri.jpg';
import Map from 'app/components/GoogleMap/Map';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  mainImage: {
    height: '150px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundImage: `url(${Homepage2})`,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  header: {
    background: theme.palette.primary.light,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: `${theme.spacing(4)}px 0`,
    color: '#fff',
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      padding: 0,
    },
  },
  mainImageDesktop: {
    position: 'absolute',
    right: theme.spacing(1),
    border: '10px solid white',
    width: '40%',
    boxShadow: theme.shadows[10],
    bottom: '-60px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  main: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  image: {
    border: '10px solid white',
    boxShadow: theme.shadows[10],
    width: '100%',
  },
  mainText: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
  },
}));

const NotConnected: ComponentType = () => {
  const classes = useStyles();
  const width = useWidth();
  const menu = useConfig((config) => config.menu);

  let columns;
  switch (width) {
    case 'xs':
      columns = 2;
      break;
    default:
      columns = 3;
  }

  return (
    <>
      <section className={classes.header}>
        <Container maxWidth="md" className={classes.main}>
          <Box width="60%" p={2} className={classes.mainText}>
            <Typography variant="h1" color="inherit">
              Velibox
            </Typography>
            <Typography variant="h6" paragraph>
              Les consignes à vélo individuelles et sécurisées de St-Etienne Métropole
            </Typography>
            <Button
              variant="contained"
              size="large"
              component={Link}
              to={{ pathname: '/subscribe', state: { withPrev: true } }}
            >
              Je m'abonne
            </Button>
          </Box>
          <img alt="main" className={classes.mainImageDesktop} src={Homepage1} />
        </Container>
      </section>
      <Container maxWidth="md">
        <Box pt={2} pb={3}>
          <Grid spacing={2} columns={columns}>
            <MenuButton
              link={{ pathname: '/our-service', state: { withPrev: true } }}
              title={menu.service}
              Icon={LocalOfferTwoTone}
            />
            <MenuButton
              link={{ pathname: '/our-offers', state: { withPrev: true } }}
              title={menu.offers}
              Icon={LocalGroceryStoreTwoTone}
            />
            <MenuButton
              link={{ pathname: '/our-boxes', state: { withPrev: true } }}
              title={menu.boxes}
              Icon={HomeTwoTone}
            />
          </Grid>
        </Box>
      </Container>
      <Box bgcolor="white" borderTop="2px solid" borderColor="grey.300">
        <Container maxWidth="md">
          <Box py={3}>
            <MaterialGrid container spacing={4}>
              <MaterialGrid item xs={12} sm={7} md={12}>
                <Typography paragraph variant="body1" align="justify">
                  Dans son ambition de développer une culture vélo sur son territoire, St Etienne Métropole a souhaité
                  offrir un service qui permette un stationnement sécurisé ponctuel des vélos et des Vélos assistance
                  électriques personnels et contribuer à la lutte contre le vol. C’est ainsi que SEM a opté pour
                  l’installation d’une dizaine de consignes individuelles soit une centaine de places sur son
                  territoire.
                </Typography>
              </MaterialGrid>
              <MaterialGrid item xs={12} sm={5}>
                <img src={Homepage2} alt="img" style={{ width: '100%' }} className={classes.image} />
              </MaterialGrid>
              <MaterialGrid item xs={12} sm={7}>
                <Typography paragraph variant="body1" align="justify">
                  Le service VELIBOX de consigne pour le stationnement d'un vélo personnel est exploité par Vélivert, il
                  est accessible à toute personne âgée de plus de 16 ans et titulaire d'une police d'assurance en
                  responsabilité civile.
                </Typography>
              </MaterialGrid>
            </MaterialGrid>
            <Box my={5} height={'400px'}>
              <Typography paragraph variant={'h6'} align={'center'}>
                Emplacement des abris
              </Typography>
              <Map />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default () => {
  const clientToken = useConfig((config) => config.clientToken);
  const NotConnectedContent = useMemo(
    () =>
      React.lazy(async () => {
        try {
          return await import('./NotConnected.' + clientToken);
        } catch (error) {
          return Promise.resolve({ default: NotConnected });
        }
      }),
    [clientToken]
  );

  return <NotConnectedContent />;
};
