import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import GridItem from './GridItem';

type PropTypes = {
  component?: React.ElementType;
  spacing?: number;
  columns?: number;
  xspacing?: number;
  yspacing?: number;
};

const useStyles = makeStyles<Theme, { spacing: number; columns: number; xspacing: number; yspacing: number }>(
  (theme) => ({
    root: {
      display: 'grid',
      gridTemplateColumns: (props) => `repeat(${props.columns}, minmax(0, 1fr))`,
      gap: (props) =>
        `${theme.spacing(props.yspacing || props.spacing)}px ${theme.spacing(props.xspacing || props.spacing)}px`,
    },
  })
);

const Grid: React.ComponentType<PropTypes & React.HTMLAttributes<HTMLElement>> = ({
  component: Component = 'div',
  spacing = 0,
  columns = 12,
  xspacing = 0,
  yspacing = 0,
  ...props
}) => {
  const classes = [useStyles({ xspacing, yspacing, spacing, columns }).root];
  if (props.className) classes.push(props.className);

  return <Component {...props} className={classes.join(' ')} />;
};

(Grid as any).Item = GridItem;

export default Grid as typeof Grid & { Item: typeof GridItem };
