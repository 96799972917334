import { SnackbarContent, makeStyles } from '@material-ui/core/';
import amber from '@material-ui/core/colors/amber';
import { Warning } from '@material-ui/icons';
import moment from 'moment';
import React, { ComponentType } from 'react';
import { useActiveSubscription } from 'app/hooks';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText(amber[200]),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const FutureSubscriptionWarningMessage: ComponentType = () => {
  const classes = useStyles();
  const [{ futureSubscription }] = useActiveSubscription();

  if (!futureSubscription) {
    return <></>;
  }

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          <Warning fontSize="small" className={classes.icon} />
          {`Votre abonnement n'est pas encore activé, il débute le ${moment(futureSubscription.validFrom).format(
            'LL'
          )}. Vous ne pouvez pas utiliser les abris pour le moment.`}
        </span>
      }
    />
  );
};

export default FutureSubscriptionWarningMessage;
