import React, { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { CurrentCandidacyMessage, NumberOfCandidacyInfoMessage } from 'app/components/Candidacies';
import {
  MultipleActiveSubscriptionErrorMessage,
  SubscriptionExtensionByCheckMessage,
  SubscriptionExtensionMessage,
  WithoutBadgeWarningMessage,
  WithoutNavigoBadgeWarningMessage,
  CurrentWaitingListMessage,
  FutureSubscriptionWarningMessage,
  SubscriptionWaitingPaymentByCheckMessage,
  TakeOffDateErrorMessage,
  NumberOfWaitingListInfoMessage,
  NumberOfSubscriptionInfoMessage,
  NoRemainingGlobalSubscriptionInfoMessage,
} from 'app/components/Subscriptions';
import WithoutSubscriptionInfoMessage from 'app/components/Subscriptions/Message/WithoutSubscriptionInfoMessage';
import {
  useActiveSubscription,
  useApiClient,
  useClub,
  useConfig,
  useContactPreferences,
  useCurrentStudioBoxAssignation,
  useIdentificators,
  useStudioWithState,
} from 'app/hooks';
import { CandidacyWorkflowContext } from 'app/contexts';
import { SubscriptionType } from 'app/types';

type MessageBoxProps = {};

const MessageBox: ComponentType<MessageBoxProps> = () => {
  /**
   * CONFIG
   */
  const rules = useConfig((config) => config.rules);

  /**
   * HOOKS
   */
  const client = useApiClient();
  const club = useClub();
  const [{ fetchPreferences }] = useContactPreferences();
  const { isLoadedCurrentCandidacy, currentCandidacy } = useContext(CandidacyWorkflowContext);
  const [{ badges, navigoBadges, isLoaded: isLoadedBadges }] = useIdentificators();
  const [
    {
      currentStudioInWaitingList,
      displayCandidateWarning,
      displayCurrentCandidate,
      displaySubscriptionWarning,
      displayWaitingListWarning,
      elementByState,
    },
  ] = useStudioWithState();
  const [{ activeSubscription, isLoaded: isLoadedSubscription, futureSubscription, activeSubscriptionCount }] =
    useActiveSubscription();
  const [{ studioBoxAssignation, isLoaded: isLoadedStudioBoxAssignation }] = useCurrentStudioBoxAssignation();

  /**
   * STATES
   */
  const [remainingGlobalSubscriptions, setRemainingGlobalSubscriptions] = useState<any>(
    rules.withReservation ? null : 0
  );
  const [checkExtensionPreference, setCheckExtensionPreference] = useState<any>();

  /**
   * HANDLERS
   */
  const getRemainingGlobalSubscriptions = useCallback(async () => {
    try {
      const data: { data: any } = await client.get(`${club['@id']}/remaining_global_subscriptions`);

      if (data && data.data.remaining_subscriptions) {
        return data.data.remaining_subscriptions;
      }

      return 0;
    } catch (error) {
      // nothing to do
    }
  }, [client, club]);

  const getCheckExtensionMessagePreference = useCallback(() => {
    fetchPreferences('check_extension_message').then((preference: any) => {
      if (preference) {
        // decode Json
        preference.value = JSON.parse(preference.value);
        return preference;
      }
    });

    return null;
  }, [fetchPreferences]);

  /**
   * MEMOS
   */
  const isExtensionMessage = useMemo(() => {
    if (isLoadedSubscription && activeSubscription) {
      return checkExtensionMessageDisplay(activeSubscription);
    }

    return false;
  }, [isLoadedSubscription, activeSubscription]);

  /**
   * EFFECTS
   */
  useEffect(() => {
    setCheckExtensionPreference(getCheckExtensionMessagePreference());
  }, [getCheckExtensionMessagePreference]);

  useEffect(() => {
    if (!rules.withReservation && club && club.xClubMaxSubscription !== null) {
      getRemainingGlobalSubscriptions().then((response) => {
        setRemainingGlobalSubscriptions(response);
      });
    } else {
      setRemainingGlobalSubscriptions(null);
    }
  }, [club, getRemainingGlobalSubscriptions, rules.withReservation]);

  /**
   * RENDER
   */
  return (
    <Box pb={2}>
      {activeSubscriptionCount > 1 && <MultipleActiveSubscriptionErrorMessage />}
      {isExtensionMessage && rules.withSubscriptionExtension && (
        <>
          {checkExtensionPreference &&
            checkExtensionPreference.value.waitingCheck &&
            !checkExtensionPreference.value.closed && (
              <SubscriptionExtensionByCheckMessage refreshPreference={getCheckExtensionMessagePreference} />
            )}
          {(!checkExtensionPreference ||
            (checkExtensionPreference.value && !checkExtensionPreference.value.waitingCheck)) && (
            <SubscriptionExtensionMessage />
          )}
        </>
      )}
      {((activeSubscription &&
        isLoadedBadges &&
        activeSubscription.accessType === 'identificator' &&
        badges.length === 0) ||
        (!activeSubscription &&
          futureSubscription &&
          isLoadedBadges &&
          futureSubscription.accessType === 'identificator' &&
          badges.length === 0)) && <WithoutBadgeWarningMessage />}
      {((activeSubscription &&
        isLoadedBadges &&
        activeSubscription.accessType === 'navigo_badge' &&
        navigoBadges.length === 0) ||
        (!activeSubscription &&
          futureSubscription &&
          isLoadedBadges &&
          futureSubscription.accessType === 'navigo_badge' &&
          navigoBadges.length === 0)) && <WithoutNavigoBadgeWarningMessage />}
      {isLoadedCurrentCandidacy && currentCandidacy && displayCurrentCandidate && (
        <CurrentCandidacyMessage currentCandidacy={currentCandidacy} />
      )}
      {currentStudioInWaitingList !== undefined && <CurrentWaitingListMessage studio={currentStudioInWaitingList} />}
      {!activeSubscription &&
        futureSubscription &&
        !(
          isLoadedStudioBoxAssignation &&
          studioBoxAssignation &&
          'waiting_payment' === studioBoxAssignation.status &&
          'pre_assigned' === studioBoxAssignation.type
        ) && <FutureSubscriptionWarningMessage />}
      {!activeSubscription &&
        !futureSubscription &&
        isLoadedStudioBoxAssignation &&
        studioBoxAssignation &&
        'waiting_payment' === studioBoxAssignation.status &&
        'pre_assigned' === studioBoxAssignation.type && (
          <SubscriptionWaitingPaymentByCheckMessage studioBoxAssignation={studioBoxAssignation} />
        )}
      {studioBoxAssignation?.takeOffDate && <TakeOffDateErrorMessage takeOffDate={studioBoxAssignation.takeOffDate} />}

      {isLoadedCurrentCandidacy && !currentCandidacy && displayCandidateWarning && (
        <NumberOfCandidacyInfoMessage numberOfElement={elementByState['can_candidacy']} />
      )}
      {displayWaitingListWarning && (
        <NumberOfWaitingListInfoMessage numberOfElement={elementByState['can_waiting_list']} />
      )}
      {!activeSubscription &&
        'all' !== rules.subscriptionType &&
        displaySubscriptionWarning &&
        !(
          isLoadedStudioBoxAssignation &&
          studioBoxAssignation &&
          'waiting_payment' === studioBoxAssignation.status &&
          'pre_assigned' === studioBoxAssignation.type
        ) && <NumberOfSubscriptionInfoMessage numberOfElement={elementByState['can_subscribe']} />}

      {!activeSubscription &&
        !futureSubscription &&
        'all' === rules.subscriptionType &&
        remainingGlobalSubscriptions !== 0 && <WithoutSubscriptionInfoMessage />}

      {!activeSubscription &&
        !futureSubscription &&
        'all' === rules.subscriptionType &&
        !rules.withReservation &&
        remainingGlobalSubscriptions === 0 && <NoRemainingGlobalSubscriptionInfoMessage />}
    </Box>
  );
};

const checkExtensionMessageDisplay = (activeSubscription: SubscriptionType) => {
  const currentDate = new Date();
  const validFrom = new Date(activeSubscription.validFrom);

  // put valid through to end of the day
  const validThrough = new Date(activeSubscription.inclusiveValidThrough);
  validThrough.setDate(validThrough.getDate() + 1);
  validThrough.setSeconds(validThrough.getSeconds() - 1);

  let difference = Math.round((validThrough.getTime() - validFrom.getTime()) / 4);

  const monthBeforeEnd = new Date();
  monthBeforeEnd.setTime(validThrough.getTime());

  monthBeforeEnd.setMonth(monthBeforeEnd.getMonth() - 2);
  const maxAuthorizedDifference = validThrough.getTime() - monthBeforeEnd.getTime();

  if (difference > maxAuthorizedDifference) {
    difference = maxAuthorizedDifference;
  }

  if (currentDate.getTime() > validThrough.getTime() - difference && currentDate.getTime() < validThrough.getTime()) {
    return true;
  }

  return false;
};

export default MessageBox;
