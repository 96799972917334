import { SnackbarContent, makeStyles } from '@material-ui/core';
import React, { ComponentType } from 'react';
import amber from '@material-ui/core/colors/amber';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText(amber[200]),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
  },
}));

type WithoutBadgeWarningMessageProps = {};

const WithoutBadgeWarningMessage: ComponentType<WithoutBadgeWarningMessageProps> = () => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          Vous n’avez pas encore de badge pour accéder à votre abri. Vous le recevrez sous 5 jours ouvrés. Il sera prêt
          à l’utilisation.
        </span>
      }
    />
  );
};

export default WithoutBadgeWarningMessage;
