import { Box, Container, makeStyles } from '@material-ui/core';
import InnerLoader from 'app/components/InnerLoader';
import { MultiStudioResumeForHomepage, StudioResumeForHomepage } from 'app/components/Studios';
import SubscriptionItem from 'app/components/SubscriptionItem';
import {
  useActiveSubscription,
  useCurrentStudioBoxAssignation,
  useDenormalizedState,
  useEntityManager,
} from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { StudioType, StudioBoxType } from 'app/types';
import React, { ComponentType, useEffect, useMemo, useState } from 'react';

const studioBoxSchema = createUnionSchema(['studioBoxes']);
const studioSchema = createUnionSchema(['studios']);

const useStyles = makeStyles(() => ({
  containerStudioResumeForHomepage: {
    padding: 0,
  },
}));

type SubscriptionBoxProps = {};

const SubscriptionBox: ComponentType<SubscriptionBoxProps> = () => {
  /**
   * HOOKS
   */
  const classes = useStyles();
  const [{ fetchEntity, fetchEntityList }, endpoints] = useEntityManager();

  const [currentStudios, setCurrentStudiosResult] = useDenormalizedState<StudioType[]>([], [studioSchema]);
  const [currentStudioBox, setCurrentStudioBoxResult] = useDenormalizedState<StudioBoxType>({}, studioBoxSchema);
  const [studioBoxes, setStudioBoxesResult] = useDenormalizedState<StudioBoxType[]>([], [studioBoxSchema]);

  const [{ activeSubscription, isLoaded: isLoadedSubscription, futureSubscription }] = useActiveSubscription();
  const [
    {
      studioBoxAssignation,
      isLoaded: isLoadedStudioBoxAssignation,
      isRefreshFinished: isRefreshFinishedStudioBoxAssignation,
    },
  ] = useCurrentStudioBoxAssignation();

  /**
   * STATES
   */
  const [isStudiosReady, setIsStudiosReady] = useState(false);

  /**
   * MEMOS
   */
  const studioIds = useMemo(() => {
    if (isLoadedStudioBoxAssignation && currentStudioBox.studio) {
      // fetch by studio box assignation if subscription is `studio_box`
      return [currentStudioBox.studio];
    } else if (activeSubscription && 'studio' === activeSubscription.scope && activeSubscription.studios) {
      // fetch by xStudioId if subscription is `studio`
      return activeSubscription.studios;
    }

    return [];
  }, [activeSubscription, isLoadedStudioBoxAssignation, currentStudioBox]);

  /**
   * EFFECTS
   */
  useEffect(() => {
    async function fetchCurrentStudios() {
      setIsStudiosReady(false);
      try {
        const newCurrentStudios = await Promise.all(
          studioIds.map(async (studioId) => {
            const data = await fetchEntity(studioId);
            return data.result;
          })
        );
        setCurrentStudiosResult(newCurrentStudios);
      } finally {
        setIsStudiosReady(true);
      }
    }

    if (
      studioIds &&
      Array.isArray(studioIds) &&
      (isRefreshFinishedStudioBoxAssignation || (activeSubscription && activeSubscription.scope === 'studio'))
    ) {
      fetchCurrentStudios();
    } else {
      setIsStudiosReady(true);
    }
  }, [
    fetchEntity,
    setCurrentStudiosResult,
    setIsStudiosReady,
    studioIds,
    isRefreshFinishedStudioBoxAssignation,
    activeSubscription,
  ]);

  // Fetch studio box (all + current)
  useEffect(() => {
    if (!isLoadedStudioBoxAssignation && studioBoxAssignation) {
      return;
    }

    (async function fetchStudioBoxes() {
      try {
        const currentStudioBoxData = await fetchEntity(studioBoxAssignation.studioBox);
        setCurrentStudioBoxResult(currentStudioBoxData.result);
        const currStudioBox = currentStudioBoxData.response.data;

        const data = await fetchEntityList(endpoints.STUDIO_BOXES, {
          params: {
            studio: currStudioBox.studio,
          },
        });
        setStudioBoxesResult(data.result);
      } catch (error) {
        // nothing to do
      }
    })();
  }, [
    endpoints.STUDIO_BOXES,
    fetchEntity,
    fetchEntityList,
    isLoadedStudioBoxAssignation,
    setCurrentStudioBoxResult,
    setStudioBoxesResult,
    studioBoxAssignation,
  ]);

  /**
   * RENDER
   */
  if (!isLoadedSubscription || !isStudiosReady) {
    return <InnerLoader />;
  }

  if (!activeSubscription) {
    return <></>;
  }

  return (
    <>
      <Box pb={2}>
        <SubscriptionItem item={activeSubscription || futureSubscription} title="Mon abonnement" />
      </Box>

      {activeSubscription && activeSubscription.studios.length > 0 && currentStudios.length === 0 && <InnerLoader />}

      {activeSubscription && 'remote' !== activeSubscription.accessType && (
        <>
          {currentStudios.length > 1 ? (
            <Box component="div" mb={3} textAlign="center">
              <Container maxWidth="sm" className={classes.containerStudioResumeForHomepage}>
                <MultiStudioResumeForHomepage
                  studios={currentStudios}
                  withInformationOnPlaces={
                    currentStudioBox
                      ? {
                          selectedStudioBox: currentStudioBox,
                          studioBoxes,
                        }
                      : undefined
                  }
                />
              </Container>
            </Box>
          ) : (
            <>
              {currentStudios.map((currentStudio) => (
                <Box key={currentStudio.name} component="div" mb={3} textAlign="center">
                  <Container maxWidth="sm" className={classes.containerStudioResumeForHomepage}>
                    <StudioResumeForHomepage
                      studio={currentStudio}
                      withInformationOnPlaces={
                        currentStudioBox
                          ? {
                              selectedStudioBox: currentStudioBox,
                              studioBoxes,
                            }
                          : undefined
                      }
                    />
                  </Container>
                </Box>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default SubscriptionBox;
