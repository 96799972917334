import React, { createContext, useContext, ComponentType } from 'react';

function createProvider<T>(useHook: () => T) {
  const Context = createContext<T>(undefined as any);

  const withProvider = (WrappedComponent: ComponentType) => {
    const Provider: ComponentType = (props) => {
      const contextValue = useHook();
      return (
        <Context.Provider value={contextValue}>
          <WrappedComponent {...props} />
        </Context.Provider>
      );
    };

    Provider.displayName = `${useHook.name}Provider`;

    return Provider;
  };

  const useProvider = () => useContext(Context);

  return [withProvider, useProvider] as [typeof withProvider, typeof useProvider];
}

export default createProvider;
