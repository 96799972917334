import React, { ComponentType, ReactNode } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ButtonBase, Typography, SvgIcon } from '@material-ui/core/';
import { Error } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      minHeight: '130px',
      boxShadow: theme.shadows[1],
      border: '3px solid transparent',
      transition: theme.transitions.create('all'),
      '&:hover': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
    disabled: {
      opacity: 0.3,
    },
    bold: {
      fontWeight: 'bold',
    },
  })
);

type SvgIconComponent = typeof SvgIcon;

type HomeButtonProps = {
  title: string;
  link: string | object;
  Icon?: SvgIconComponent;
  CustomIcon?: ReactNode;
  warning?: boolean;
  boldTitle?: boolean;
};

type ButtonBaseProps = {
  [key: string]: any;
};

const MenuButton: ComponentType<ButtonBaseProps & HomeButtonProps> = ({
  warning,
  title,
  link,
  Icon,
  CustomIcon = null,
  boldTitle = false,
  ...buttonProps
}) => {
  const classes = useStyles();

  return (
    <ButtonBase classes={classes} focusRipple to={link} component={Link} {...buttonProps}>
      {warning && <Error fontSize="small" />}
      <Typography variant="h6" gutterBottom align="center" className={boldTitle ? classes.bold : ''}>
        {title}
      </Typography>
      {Icon && <Icon fontSize="large" />}
      {CustomIcon}
    </ButtonBase>
  );
};

export default MenuButton;
