/**
 * SubscriptionsWorkflowContext.
 *
 * @description Manage subscription workflow.
 */

import React, { createContext, ReactNode, useEffect, useState } from 'react';
import createPersistedState from 'use-persisted-state';
import { useCurrentStudioBoxAssignation, useDenormalizedState, useEntityManager } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { ProductType, StudioBoxType, StudioType } from 'app/types';

type SubscriptionsWorkflow = {
  clearCurrentProduct: () => void;
  currentOfferId?: string;
  currentProduct: ProductType | null;
  currentStartDate?: string;
  currentStudios?: StudioType[];
  currentStudioBox?: StudioBoxType;
  currentStudioBoxAssignationId?: string;
  setCurrentOfferId: (offerId?: string) => void;
  setCurrentProduct: (product: ProductType | null) => void;
  setCurrentStartDate: (date?: string) => void;
  setCurrentStudios: (studio: StudioType[]) => void;
  setCurrentStudioBox: (studioBox?: StudioBoxType) => void;
  setCurrentStudioBoxAssignationId: (studioBoxAssignation?: string) => void;
};

type SubscriptionsWorkflowProps = {
  children: ReactNode;
};

const initialSubscriptionsWorkflowContext: SubscriptionsWorkflow = {
  clearCurrentProduct: () => {
    // nothing to do
  },
  currentProduct: null,
  setCurrentOfferId: (offerId?: string) => {
    // nothing to do
  },
  setCurrentProduct: (product: ProductType | null) => {
    // nothing to do
  },
  setCurrentStartDate: (date?: string) => {
    // nothing to do
  },
  setCurrentStudios: (studio: StudioType[]) => {
    return [];
  },
  setCurrentStudioBox: (studioBox?: StudioBoxType) => {
    // nothing to do
  },
  setCurrentStudioBoxAssignationId: (studioBoxAssignation?: string) => {
    // nothing to do
  },
};

export const SubscriptionsWorkflowContext = createContext<SubscriptionsWorkflow>(initialSubscriptionsWorkflowContext);
SubscriptionsWorkflowContext.displayName = 'SubscriptionsWorkflowContext';

const studioBoxSchema = createUnionSchema(['studioBoxes']);
const studioSchema = createUnionSchema(['studios']);
const usePersistedState = createPersistedState('currentProduct');

export const SubscriptionsWorkflowProvider = ({ children }: SubscriptionsWorkflowProps): JSX.Element => {
  const [currentOfferId, setCurrentOfferId] = useState<string>();
  const [currentProduct, setCurrentProduct] = usePersistedState<ProductType | null>(null);
  const [currentStartDate, setCurrentStartDate] = useState<string>();
  const [currentStudios, setCurrentStudios] = useState<StudioType[]>([]);
  const [currentStudioBox, setCurrentStudioBox] = useState<StudioBoxType>();
  const [currentStudioBoxAssignationId, setCurrentStudioBoxAssignationId] = useState<string>();
  const clearCurrentProduct = () => setCurrentProduct(null);

  // If current studio box assignation already exists
  const [{ fetchEntity }] = useEntityManager();
  const [{ studioBoxAssignation, isLoaded: isLoadedStudioBoxAssignation }] = useCurrentStudioBoxAssignation();
  const studioBoxAssignationId = studioBoxAssignation && studioBoxAssignation['@id'];
  const studioBoxId = studioBoxAssignation && studioBoxAssignation.studioBox;

  // Update currentStudioBoxAssignationId
  useEffect(() => {
    if (isLoadedStudioBoxAssignation) setCurrentStudioBoxAssignationId(studioBoxAssignationId);
  }, [isLoadedStudioBoxAssignation, setCurrentStudioBoxAssignationId, studioBoxAssignationId]);

  // - fetch Studio Box
  const [studioBox, setStudioBoxResult] = useDenormalizedState<StudioBoxType>({}, studioBoxSchema);
  const [isLoadedStudioBox, setLoadedStudioBox] = useState<boolean>();

  useEffect(() => {
    async function fetchStudioBox() {
      setLoadedStudioBox(false);
      try {
        const data: { result: any } = await fetchEntity(studioBoxId);
        setStudioBoxResult(data.result);
        setLoadedStudioBox(true);
      } catch (error) {
        // nothing to do
      }
    }

    if (isLoadedStudioBoxAssignation && studioBoxId) fetchStudioBox();
  }, [fetchEntity, isLoadedStudioBoxAssignation, setLoadedStudioBox, setStudioBoxResult, studioBoxId]);

  // Update currentStudioBox
  useEffect(() => {
    if (isLoadedStudioBox && isLoadedStudioBoxAssignation && studioBox) setCurrentStudioBox(studioBox);
  }, [isLoadedStudioBox, isLoadedStudioBoxAssignation, setCurrentStudioBox, studioBox]);

  // - fetch Studio
  const [studio, setStudioResult] = useDenormalizedState<StudioType>({}, studioSchema);
  const [isLoadedStudio, setLoadedStudio] = useState<boolean>();

  const studioId = studioBox && studioBox.studio;
  useEffect(() => {
    async function fetchStudio() {
      setLoadedStudio(false);
      try {
        const data: { result: any } = await fetchEntity(studioId);
        setStudioResult(data.result);
        setLoadedStudio(true);
      } catch (error) {
        // nothing to do
      }
    }

    if (
      (!currentStudios || currentStudios.length === 0) &&
      isLoadedStudioBox &&
      isLoadedStudioBoxAssignation &&
      studioId
    ) {
      fetchStudio();
    }
  }, [
    currentStudios,
    fetchEntity,
    isLoadedStudioBox,
    isLoadedStudioBoxAssignation,
    setLoadedStudio,
    setStudioResult,
    studioId,
  ]);

  // Update currentStudio
  useEffect(() => {
    if ((!currentStudios || currentStudios.length === 0) && isLoadedStudio && isLoadedStudioBoxAssignation && studio)
      setCurrentStudios([studio]);
  }, [currentStudios, isLoadedStudio, isLoadedStudioBoxAssignation, setCurrentStudios, studio]);

  return (
    <SubscriptionsWorkflowContext.Provider
      value={{
        clearCurrentProduct,
        currentOfferId,
        currentProduct,
        currentStartDate,
        currentStudios,
        currentStudioBox,
        currentStudioBoxAssignationId,
        setCurrentOfferId,
        setCurrentProduct,
        setCurrentStartDate,
        setCurrentStudios,
        setCurrentStudioBox,
        setCurrentStudioBoxAssignationId,
      }}
    >
      {children}
    </SubscriptionsWorkflowContext.Provider>
  );
};
