import React, { ComponentType, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Typography, Hidden, Menu, MenuItem } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { useConnectedContact, useAuthentication, useConfig } from 'app/hooks';

const useStyles = makeStyles((theme) => ({
  contactName: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    color: (props: any) => theme.palette.getContrastText(props.headerColor),
  },
  avatarLogin: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

const AccountButton: ComponentType = () => {
  /** HOOKS **/
  const theme = useConfig((config) => config.theme);
  const props = { headerColor: theme.headerColor || '#fff' };
  const classes = useStyles(props);

  const [{ contact }] = useConnectedContact();
  const [, { logout }] = useAuthentication();
  const { history, location } = useReactRouter();

  /** STATES **/
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /** HANDLERS **/
  const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    contact ? setAnchorEl(event.currentTarget) : history.push('/login');
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
    history.push('/login');
  };

  /** EFFECTS **/
  useEffect(() => {
    setAnchorEl(null);
  }, [location]);

  return contact ? (
    <>
      <Hidden xsDown>
        <Button aria-controls="account-menu" aria-haspopup="true" onClick={handleAccountClick}>
          <Box pr={1}>
            <Typography className={classes.contactName} variant="body1">{`${contact.givenName || ''} ${(
              contact.familyName || ''
            ).toUpperCase()}`}</Typography>
          </Box>
          <Avatar>
            <Person />
          </Avatar>
        </Button>
        <Menu id="account-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleAccountMenuClose}>
          <MenuItem component={Link} to={{ pathname: '/my-account', state: { withPrev: true } }}>
            Mon compte
          </MenuItem>
          <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
        </Menu>
      </Hidden>
    </>
  ) : (
    <Button component={Link} to="/login" color="primary">
      <Avatar className={classes.avatarLogin}>
        <Person />
      </Avatar>
    </Button>
  );
};

export default AccountButton;
