/* eslint-disable no-process-env */

import { useMemo } from 'react';
import logoParis from 'app/assets/paris/logo.png';
import logoSaintCloud from 'app/assets/saintcloud/logo.png';
import logoSaintCloudFooter from 'app/assets/saintcloud/logo_footer.png';
import faviconSaintCloud from 'app/assets/saintcloud/favicon.jpg';
import logoSaintEtienneFooter from 'app/assets/saintetienne/logo_footer.png';
import faviconSaintEtienne from 'app/assets/saintetienne/favicon.png';
import logoGap from 'app/assets/gap/logo.png';
import faviconGap from 'app/assets/gap/favicon.png';
import logoSaclay from 'app/assets/saclay/logo.png';
import logoSaclayFooter from 'app/assets/saclay/logoFooter.png';
import faviconSaclay from 'app/assets/saclay/favicon.png';
import logoRatp from 'app/assets/ratp/logo.png';
import logoFooterRatp from 'app/assets/ratp/LogoFooter.png';
import faviconRatp from 'app/assets/ratp/favicon.png';

type ClientConfig = {
  theme: {
    textColor?: string;
    primaryColor: string;
    secondaryColor?: string;
    headerColor?: string;
    footerColor?: string;
    logo: string;
    logo_size?: string;
    logoFooter?: string;
    favicon?: string;
    title?: string;
    nameSender?: string;
    emailSender?: string;
    emailTarget: string;
    targetType: string;
    contactModelId: string;
    contactFormSubjectValues?: Array<string>;
    customFont?: string;
  };
  rules: {
    availableAccessTypes: Array<
      'sofialocks_phone' | 'sofialocks_card' | 'identificator' | 'remote' | 'navigo_badge' | 'passpass_badge' | 'qrcode'
    >;
    availablePaymentMeans: Array<'cheque' | 'CBWeb'>;
    availableWaitingList: 'none' | 'waiting' | 'active';
    candidateOptions?: Array<'child_seat' | 'electrical_outlet'>;
    minimumAge: number;
    postalCodeRule: RegExp;
    postalCodeErrorMessage: string;
    provideProofOfAddress: boolean;
    subscriptionType: string;
    withSubscriptionExtension: boolean;
    withReservation: boolean;
    withForceCardBeforeSubscription: 'none' | 'navigo_badge';
    addBadge: boolean;
    deleteBadge: boolean;
    captchaType: 'default' | 'mtcaptcha';
  };
  i18n: {
    accessTypes: {
      [key: string]: {
        label: string;
        description?: string;
      };
    };
    candidateOptions?: {
      [key: string]: {
        label: string;
      };
    };
    paymentMeans: {
      [key: string]: {
        label: string;
      };
    };
  };
  footer: {
    contact: boolean;
    phone?: string;
    mail?: string;
    logo_url?: string;
    address?: {
      name: string;
      address: string;
      postal_code: string;
      city: string;
    };
  };
  menu: {
    service: string;
    offers: string;
    boxes: string;
  };
  customWording?: {
    studioCapacity?: string;
  };
  legalNotice: {
    active: boolean;
    url_prod: string;
    city: string;
    service_name?: string;
    publication_director: string;
    editorial_manager?: string;
  };
  mapDisplay: {
    availablePlaces: boolean;
    remainingSubscriptions: boolean;
  };
  consent?: {
    newsletters: boolean;
    idfm: boolean;
  };
};

type ClientToken = 'saintetienne' | 'paris' | 'saintcloud' | 'gap' | 'saclay' | 'ratp' | 'default';

const clientConfigs: Record<ClientToken, ClientConfig> = {
  saintetienne: {
    theme: {
      primaryColor: '#64b445',
      secondaryColor: '#fff',
      favicon: faviconSaintEtienne,
      logoFooter: logoSaintEtienneFooter,
      logo: 'https://www.saint-etienne-metropole.fr/themes/custom/sem/img/logo.png',
      logo_size: 'logoBig',
      title: 'Les consignes à vélos de Saint-Étienne Métropole',
      emailTarget: '/saintetienne/users/47',
      targetType: 'user',
      contactModelId: '/saintetienne/email_models/26',
    },
    rules: {
      availableAccessTypes: ['remote'],
      availablePaymentMeans: ['CBWeb'],
      availableWaitingList: 'none',
      minimumAge: 16,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'all',
      withSubscriptionExtension: false,
      withReservation: true,
      withForceCardBeforeSubscription: 'none',
      addBadge: false,
      deleteBadge: false,
      captchaType: 'default',
    },
    i18n: {
      accessTypes: {
        remote: {
          label: 'Site Web',
          description: "(L'accès à votre abri sera effectif 30 minutes après la validation de votre paiement)",
        },
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire',
        },
      },
    },
    footer: {
      contact: true,
      phone: '04 77 37 18 36',
      mail: 'consignevelo@saint-etienne-metropole.fr',
      address: {
        name: 'Agence VéliVert',
        address: 'Rue Jacques-Constant Milleret',
        postal_code: '42000',
        city: 'SAINT-ETIENNE',
      },
    },
    menu: {
      service: 'Découvrir le service',
      offers: "Nos offres d'abonnement",
      boxes: 'Découvrir nos consignes à vélo',
    },
    legalNotice: {
      active: true,
      url_prod: 'https://consignevelo.saint-etienne-metropole.fr/',
      city: 'la ville de Saint-Etienne',
      service_name: 'VELIGO',
      publication_director: 'Gaël Perdriau',
      editorial_manager: 'Olivier Barbé',
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: true,
    },
  },
  paris: {
    theme: {
      primaryColor: '#00A5AF',
      secondaryColor: '#fff',
      footerColor: '#071f32',
      headerColor: '#FFFFFF',
      logo: logoParis,
      logoFooter: logoParis,
      title: 'Abris à vélos sécurisés de la ville de Paris',
      favicon: 'https://www.paris.fr/favicon.ico',
      emailTarget: '/paris/users/4',
      targetType: 'user',
      contactModelId: '/paris/email_models/3',
      emailSender: 'altinnova-paris@stadline.com',
      nameSender: 'Ville de Paris',
    },
    rules: {
      availableAccessTypes: ['sofialocks_card', 'sofialocks_phone'],
      availablePaymentMeans: ['CBWeb', 'cheque'],
      availableWaitingList: 'active',
      candidateOptions: ['child_seat'],
      minimumAge: 14,
      postalCodeRule: /^75[0-9]{3}$/,
      postalCodeErrorMessage: 'Le code postal est requis et doit commencer par 75',
      provideProofOfAddress: true,
      subscriptionType: 'studio',
      withSubscriptionExtension: true,
      withReservation: false,
      withForceCardBeforeSubscription: 'none',
      addBadge: false,
      deleteBadge: false,
      captchaType: 'default',
    },
    i18n: {
      accessTypes: {
        sofialocks_card: {
          label: 'badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)',
        },
        sofialocks_phone: {
          label: 'application mobile',
          description: "(L'accès à votre abri sera effectif 30 minutes après la validation de votre paiement)",
        },
      },
      candidateOptions: {
        child_seat: {
          label: 'Siège enfant',
        },
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire',
        },
        cheque: {
          label: 'chèque',
        },
      },
    },
    footer: {
      contact: false,
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir notre offre',
      boxes: 'Découvrir nos abris',
    },
    legalNotice: {
      active: true,
      url_prod: 'https://www.abris-securises-velos.paris.fr/',
      city: 'la ville de PARIS',
      publication_director: "M. Le Président d'ALTINNOVA",
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: true,
    },
  },
  saintcloud: {
    theme: {
      primaryColor: '#00405e',
      secondaryColor: '#fff',
      footerColor: '#00405e',
      logo: logoSaintCloud,
      logoFooter: logoSaintCloudFooter,
      title: 'Véligo à Saint-Cloud',
      favicon: faviconSaintCloud,
      emailTarget: '/saintcloud/users/41',
      targetType: 'user',
      contactModelId: '/saintcloud/email_models/14',
    },
    rules: {
      availableAccessTypes: ['identificator', 'navigo_badge'],
      availablePaymentMeans: ['CBWeb'],
      availableWaitingList: 'none',
      minimumAge: 16,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'studio',
      withSubscriptionExtension: false,
      withReservation: false,
      withForceCardBeforeSubscription: 'none',
      addBadge: false,
      deleteBadge: false,
      captchaType: 'default',
    },
    i18n: {
      accessTypes: {
        identificator: {
          label: 'Badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)',
        },
        navigo_badge: {
          label: 'Badge (Navigo)',
        },
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire',
        },
      },
    },
    footer: {
      contact: true,
      mail: 'saintcloud@monabrivelo.com',
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir notre offre',
      boxes: 'Découvrir notre abri',
    },
    legalNotice: {
      active: true,
      url_prod: 'www.veligo.saintcloud.fr',
      city: 'la ville de Saint-Cloud',
      publication_director: 'ERIC BERDOATI – Maire',
      editorial_manager: 'ERIC BERDOATI – Maire',
    },
    mapDisplay: {
      availablePlaces: true,
      remainingSubscriptions: true,
    },
  },
  gap: {
    theme: {
      primaryColor: '#009DDE',
      secondaryColor: '#7bc13f',
      logo: logoGap,
      favicon: faviconGap,
      logo_size: 'logoBig',
      title: 'Les abris vélos',
      emailTarget: '/gap/users/48',
      targetType: 'user',
      contactModelId: '/gap/email_models/22',
    },
    rules: {
      availableAccessTypes: ['identificator', 'qrcode'],
      availablePaymentMeans: ['CBWeb'],
      availableWaitingList: 'none',
      minimumAge: 16,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'all',
      withSubscriptionExtension: false,
      withReservation: false,
      withForceCardBeforeSubscription: 'none',
      addBadge: false,
      deleteBadge: false,
      captchaType: 'default',
    },
    i18n: {
      accessTypes: {
        identificator: {
          label: 'Badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)',
        },
        qrcode: {
          label: 'QR Code',
        },
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire',
        },
      },
    },
    footer: {
      contact: false,
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir notre offre',
      boxes: 'Découvrir nos abris',
    },
    legalNotice: {
      active: true,
      url_prod: '[URL DU SITE PROD]',
      city: 'la ville de Gap',
      service_name: 'Direction de la Communication',
      publication_director: "Roger DIDIER Président de la Communauté d'Agglomération Gap-Tallard-Durance",
      editorial_manager: 'Alexandre DELIA Chef de projet communication',
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: false,
    },
  },
  saclay: {
    theme: {
      primaryColor: '#FCD808',
      secondaryColor: '#000',
      logo: logoSaclay,
      logoFooter: logoSaclayFooter,
      logo_size: 'logoBig',
      title: 'Parcs à Vélos',
      favicon: faviconSaclay,
      emailTarget: '/saclay/users/55',
      targetType: 'user',
      contactModelId: '/saclay/email_models/18',
    },
    rules: {
      availableAccessTypes: ['navigo_badge', 'identificator'],
      availablePaymentMeans: ['CBWeb'],
      availableWaitingList: 'active',
      minimumAge: 16,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'studio',
      withSubscriptionExtension: false,
      withReservation: false,
      withForceCardBeforeSubscription: 'none',
      addBadge: false,
      deleteBadge: false,
      captchaType: 'default',
    },
    i18n: {
      accessTypes: {
        identificator: {
          label: 'Badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)',
        },
        navigo_badge: {
          label: 'Badge (Navigo)',
        },
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire',
        },
      },
    },
    footer: {
      contact: true,
      mail: 'parcavelos@paris-saclay.com',
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir nos offres',
      boxes: 'Découvrir nos parcs',
    },
    legalNotice: {
      active: true,
      url_prod: 'http://www.paris-saclay.com/parcsavelos',
      city: 'Paris Saclay',
      service_name: 'Parc à Vélos',
      publication_director: 'Michel Bournat, Président de la communauté d’agglomération',
      editorial_manager: 'Séverine Alfaïate, Directrice de la communication',
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: false,
    },
  },
  ratp: {
    theme: {
      primaryColor: '#0076BD',
      secondaryColor: '#72afc9',
      footerColor: '#415E7D',
      favicon: faviconRatp,
      logo: logoRatp,
      logo_size: 'logoBig',
      logoFooter: logoFooterRatp,
      title: 'Les abris vélos',
      emailTarget: '/ratp/users/9',
      targetType: 'user',
      contactModelId: '/ratp/email_models/11',
      contactFormSubjectValues: ['Vérifier manuellement mon éligibilité à la gratuité'],
      customFont: 'GillSansMt',
    },
    rules: {
      availableAccessTypes: ['navigo_badge', 'identificator'],
      availablePaymentMeans: ['CBWeb'],
      availableWaitingList: 'none',
      minimumAge: 0,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'all',
      withSubscriptionExtension: true,
      withReservation: false,
      withForceCardBeforeSubscription: 'navigo_badge',
      addBadge: true,
      deleteBadge: true,
      captchaType: 'mtcaptcha',
    },
    i18n: {
      accessTypes: {
        identificator: {
          label: 'Badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)',
        },
        navigo_badge: {
          label: 'Badge (Navigo)',
        },
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire',
        },
      },
    },
    footer: {
      contact: false,
    },
    menu: {
      service: 'Comment ça marche ?',
      offers: 'Combien ça coûte ?',
      boxes: 'Où trouver nos Parkings Vélos ?',
    },
    customWording: {
      studioCapacity: 'Capacité',
    },
    legalNotice: {
      active: true,
      url_prod: '[URL DU SITE PROD]',
      city: '[NOM DE LA REGION OU VILLE]',
      service_name: '[NOM DU SERVICE]',
      publication_director: 'Prénom Nom + poste',
      editorial_manager: 'Prénom Nom + poste',
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: true,
    },
    consent: {
      newsletters: true,
      idfm: true,
    },
  },
  default: {
    theme: {
      primaryColor: '#b6b2b0',
      secondaryColor: '#fff',
      logo: '',
      title: 'Les abris vélos',
      emailTarget: '',
      targetType: 'user',
      contactModelId: '',
    },
    rules: {
      availableAccessTypes: [],
      availablePaymentMeans: [],
      availableWaitingList: 'none',
      minimumAge: 16,
      postalCodeRule: /^[\d]{5}$/,
      postalCodeErrorMessage: 'Le code postal est requis',
      provideProofOfAddress: false,
      subscriptionType: 'studio',
      withSubscriptionExtension: false,
      withReservation: false,
      withForceCardBeforeSubscription: 'none',
      addBadge: false,
      deleteBadge: false,
      captchaType: 'default',
    },
    i18n: {
      accessTypes: {
        identificator: {
          label: 'Badge',
          description: '(Le badge sera délivré sous 7 jours par voie postale)',
        },
      },
      paymentMeans: {
        CBWeb: {
          label: 'carte bancaire',
        },
      },
    },
    footer: {
      contact: false,
    },
    menu: {
      service: 'Découvrir le service',
      offers: 'Découvrir notre offre',
      boxes: 'Découvrir nos abris',
    },
    legalNotice: {
      active: false,
      url_prod: '[URL DU SITE PROD]',
      city: '[NOM DE LA REGION OU VILLE]',
      service_name: '[NOM DU SERVICE]',
      publication_director: 'Prénom Nom + poste',
      editorial_manager: 'Prénom Nom + poste',
    },
    mapDisplay: {
      availablePlaces: false,
      remainingSubscriptions: false,
    },
    consent: {
      newsletters: true,
      idfm: false,
    },
  },
};

const getEnv = (name: string) => {
  // @ts-ignore
  if (window.env !== undefined && window.env[name] !== undefined) {
    // @ts-ignore
    return window.env[name];
  }

  return process.env[name];
};

const envConfig = {
  clientToken: getEnv('REACT_APP_CLIENT_TOKEN') as ClientToken,
  api: {
    url: getEnv('REACT_APP_API_URL'),
    withMoreLogs: getEnv('REACT_APP_DEV_API_MORE_LOGS'),
  },
  oauth: {
    url: getEnv('REACT_APP_API_URL'),
    clientId: getEnv('REACT_APP_OAUTH_CLIENT_ID'),
    clientSecret: getEnv('REACT_APP_OAUTH_CLIENT_SECRET'),
    anonymousClientId: getEnv('REACT_APP_OAUTH_ANONYMOUS_CLIENT_ID'),
    anonymousClientSecret: getEnv('REACT_APP_OAUTH_ANONYMOUS_CLIENT_SECRET'),
  },
  recaptcha: {
    siteKey: getEnv('REACT_APP_RECAPTCHA_SITE_KEY'),
  },
  mtcaptcha: {
    siteKey: getEnv('REACT_APP_MTCAPTCHA_SITE_KEY'),
  },
  googleMaps: {
    apiKey: getEnv('REACT_APP_MAPS_API_KEY'),
  },
};

type ConfigType = typeof envConfig & ClientConfig;
type SelectorType<T = any> = (config: ConfigType) => T;
type UseConfigType = <T = ConfigType>(selector?: SelectorType<T>) => T;

const defaultSelector: SelectorType = (config) => config;
const useConfig: UseConfigType = (selector = defaultSelector) => {
  const clientConfig =
    clientConfigs[envConfig.clientToken] === undefined
      ? clientConfigs['default']
      : clientConfigs[envConfig.clientToken];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => selector({ ...envConfig, ...clientConfig }), [envConfig, clientConfig]);
};

export default useConfig;
