import { useEffect, useState, useCallback, useMemo } from 'react';
import { useEntityManager, useDenormalizedState, useConnectedContact, useActiveSubscription } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { StudioBoxAssignationType } from 'app/types/index';
import createProvider from './createProvider';
import getReservationEndDate from 'app/helpers/reservationEndDate';

const studioBoxAssignationSchema = createUnionSchema(['studio_box_assignations']);

const useCurrentStudioBoxAssignation = () => {
  const [{ contact }] = useConnectedContact();

  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isRefreshFinished, setIsRefreshFinished] = useState(false);
  const [{ activeSubscription }] = useActiveSubscription();
  const [studioBoxAssignations, setStudioBoxAssignationsResult] = useDenormalizedState<StudioBoxAssignationType[]>(
    [],
    [studioBoxAssignationSchema]
  );

  const contactId = useMemo(() => {
    return contact && contact['@id'];
  }, [contact]);

  const fetch = useCallback(
    async (isRefresh = false) => {
      // retrieve access token, using provided code
      try {
        setIsRefreshFinished(false);
        setIsLoaded(false);
        const data = await fetchEntityList(endpoints.STUDIO_BOX_ASSIGNATIONS, {
          params: {
            contact: contactId,
            expired: false,
          },
        });
        setStudioBoxAssignationsResult(data.result);
        setIsLoaded(true);

        if (isRefresh) {
          setIsRefreshFinished(true);
        }
      } catch (error) {
        setIsLoaded(true);
      }
    },
    [contactId, endpoints.STUDIO_BOX_ASSIGNATIONS, fetchEntityList, setStudioBoxAssignationsResult]
  );

  useEffect(() => {
    if (contactId) {
      fetch();
    }
  }, [contactId, endpoints.STUDIO_BOX_ASSIGNATIONS, fetch, fetchEntityList]);

  const notTerminatedAssignations = useMemo(() => {
    return studioBoxAssignations.filter((item) => {
      return item.status !== 'terminated' && item.status !== 'regularized';
    });
  }, [studioBoxAssignations]);

  const clearStudioBoxAssignation = useCallback(
    () => setStudioBoxAssignationsResult([]),
    [setStudioBoxAssignationsResult]
  );

  const data = {
    studioBoxAssignation: notTerminatedAssignations[0],
    isLoaded,
    validThrough: activeSubscription
      ? getReservationEndDate(notTerminatedAssignations[0], activeSubscription)
      : undefined,
    isRefreshFinished,
  };

  const actions = {
    fetch,
    clearStudioBoxAssignation,
  };

  return [data, actions] as [typeof data, typeof actions];
};

const [withCurrentStudioBoxAssignation, useProvidedCurrentStudioBoxAssignation] =
  createProvider(useCurrentStudioBoxAssignation);

export { withCurrentStudioBoxAssignation };

export default useProvidedCurrentStudioBoxAssignation;
