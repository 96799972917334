import { Button, SnackbarContent, makeStyles } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText('#fff'),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
  },
}));

type NumberOfSubscriptionInfoMessageProps = {};

const WithoutSubscriptionInfoMessage: ComponentType<NumberOfSubscriptionInfoMessageProps> = () => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          Vous n'avez pas d'abonnement actif, veuillez vous abonner pour profiter de nos services.
        </span>
      }
      action={
        <Button size="small" component={Link} to="/subscriptions">
          Je m'abonne
        </Button>
      }
    />
  );
};

export default WithoutSubscriptionInfoMessage;
