import { useMemo } from 'react';
import useConfig from './useConfig';

const endpointRoutes = {
  ARTICLES: '/:clientToken/articles',
  CLUBS: '/:clientToken/clubs',
  CANDIDACIES: '/:clientToken/candidacies',
  CONTACT_PREFERENCES: '/:clientToken/contact_preferences',
  CONTACT_TAGS: '/:clientToken/contact_tags',
  CONTACT_USERS: '/:clientToken/contactUsers',
  CONTACTS: '/:clientToken/contacts',
  EMAIL_REQUESTS: '/:clientToken/email_requests',
  ENTRIES: '/:clientToken/entries',
  FILE_ADD_PROOF_OF_ADDRESS: '/:clientToken/files/medical_certificates',
  FILE_PROOF_OF_ADDRESS: '/:clientToken/medical_certificates',
  IDENTIFICATORS: '/:clientToken/identificators',
  INVOICES: '/:clientToken/invoices',
  MUNICIPALITIES: '/:clientToken/municipalities',
  OFFERS: '/:clientToken/offers',
  OPTINS: '/:clientToken/optins',
  PASSWORD_REQUESTS: '/:clientToken/password_requests',
  PAYMENT_TRANSACTIONS: '/:clientToken/payment_transactions',
  PRODUCTS: '/:clientToken/products',
  SALES: '/:clientToken/sales',
  STUDIO_BOXES: '/:clientToken/studio_boxes',
  STUDIO_CANDIDACIES: '/:clientToken/studio_candidacies',
  STUDIOS: '/:clientToken/studios',
  STUDIOS_PUBLIC: '/:clientToken/public/v1/studios',
  STUDIOS_WITH_STATE: '/:clientToken/studios_with_state',
  SUBSCRIPTIONS: '/:clientToken/subscriptions',
  STUDIO_BOX_ASSIGNATIONS: '/:clientToken/studio_box_assignations',
  WAITING_LISTS: '/:clientToken/waiting_lists',
  WAITING_LIST_ITEMS: '/:clientToken/waiting_list_items',
  SECURITY_POLICIES: '/:clientToken/security_policies',
};

const useEndpoints = () => {
  const clientToken = useConfig((config) => config.clientToken);

  const endpoints = useMemo(
    () =>
      Object.entries(endpointRoutes).reduce((all, [key, route]) => {
        all[key] = route.replace('/:clientToken', `/${clientToken}`);
        return all;
      }, {} as Record<string, string>),
    [clientToken]
  );

  return endpoints as typeof endpointRoutes;
};

export default useEndpoints;
