import React, { ComponentType } from 'react';
import { Box, Typography, Chip, Paper, Button } from '@material-ui/core/';
import classNames from 'classnames';
import moment from 'moment';
import { lightGreen, deepOrange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { SubscriptionType } from 'app/types';
import { convertDatesToDiff } from 'app/helpers/date';
import imgBadgeAppStore from 'app/assets/badge-app-store.png';
import imgBadgeGooglePlay from 'app/assets/badge-google-play.png';
import { useIdentificators, useWidth } from 'app/hooks';
import QrCodeDialog from 'app/pages/Badges/QrCodeDialog';

type SubscriptionItemProps = {
  item: SubscriptionType;
  title?: string;
  withAccessType?: boolean;
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: '10px',
    backgroundColor: lightGreen[500],
    color: '#fff',
  },
  chipError: {
    backgroundColor: deepOrange[500],
  },
  badge: {
    width: '48%',
  },
}));

const SubscriptionItem: ComponentType<SubscriptionItemProps> = ({ item, title, withAccessType = false }) => {
  const classes = useStyles();

  const { name, validFrom, inclusiveValidThrough, accessType } = item;

  let active = true;
  let statusLabel = 'Actif';
  let dateText = `Abonnement en cours`;

  if (item && moment(validFrom).isAfter(moment().endOf('day'))) {
    active = false;
    statusLabel = 'Inactif';
    dateText = `Démarrera dans ${convertDatesToDiff(moment().toDate(), validFrom)}`;
  } else if (item && moment(inclusiveValidThrough).isBefore(moment().startOf('day'))) {
    active = false;
    statusLabel = 'Terminé ';
    dateText = `Terminé depuis ${convertDatesToDiff(inclusiveValidThrough, moment().toDate())}`;
  }

  // Qrcode popin management
  const width = useWidth();
  const [{ qrCodes, isLoaded: isLoadedBadges }, { fetchQrCode }] = useIdentificators();
  const [open, setOpen] = React.useState(false);
  const [qrcode, setQrcode] = React.useState<string>('');

  let fileFormat = 'pdf';
  let fileType = 'application/pdf';

  if (width === 'xs') {
    fileFormat = 'html';
    fileType = 'text/html';
  }

  const displayQrCode = (badgeIri: string) => {
    if (!open) {
      fetchQrCode(badgeIri, fileFormat).then((data) => {
        setQrcode(URL.createObjectURL(new Blob([data], { type: fileType })));
        setOpen(true);
      });
    }
  };

  const onCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Paper>
        <Box p={2}>
          <Box pb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="caption" color="textSecondary">
              {title || dateText}
            </Typography>
            <Chip
              size="small"
              label={statusLabel}
              className={classNames(classes.chip, { [classes.chipError]: !active })}
            />
          </Box>
          <Typography variant="h6" gutterBottom>
            {name}
          </Typography>
          <Typography variant="body2">{`Valide du ${moment(validFrom).format('LL')} au ${moment(
            inclusiveValidThrough
          ).format('LL')}`}</Typography>
          {item && accessType === 'qrcode' && isLoadedBadges && qrCodes.length > 0 && (
            <>
              <Box my={1} />
              <Button aria-label="display" onClick={() => displayQrCode(qrCodes[0]['@id'])}>
                Afficher le QR Code
              </Button>
            </>
          )}
        </Box>
      </Paper>
      {withAccessType && item.accessType && (
        <Paper>
          <Box p={2} mt={2}>
            <Box pb={2}>
              <Typography variant="caption" color="textSecondary">
                Ma méthode d'accès
              </Typography>
            </Box>
            {item && 'identificator' === item.accessType && (
              <Typography gutterBottom variant="h6">
                Badge
              </Typography>
            )}
            {item && 'navigo_badge' === item.accessType && (
              <Typography gutterBottom variant="h6">
                Badge (Navigo)
              </Typography>
            )}
            {item && 'passpass_badge' === item.accessType && (
              <Typography gutterBottom variant="h6">
                Badge (Pass Pass)
              </Typography>
            )}
            {item && 'qrcode' === item.accessType && (
              <Typography gutterBottom variant="h6">
                QR Code
              </Typography>
            )}
            {item && 'remote' === item.accessType && (
              <Typography gutterBottom variant="h6">
                Site internet
              </Typography>
            )}
            {item && 'sofialocks_card' === item.accessType && (
              <Typography gutterBottom variant="h6">
                Badge
              </Typography>
            )}
            {item && 'sofialocks_phone' === item.accessType && (
              <>
                <Typography gutterBottom variant="h6">
                  Application mobile
                </Typography>

                <Box pb={2} display="flex" justifyContent="space-between" alignItems="center">
                  <a
                    target={'_blank'}
                    href={'https://apps.apple.com/gb/app/ma-cl%C3%A9/id1498897440?l=fr'}
                    rel="noreferrer"
                  >
                    <img src={imgBadgeAppStore} className={classes.badge} alt="Badge AppStore" />
                  </a>
                  <a
                    target={'_blank'}
                    href={'https://play.google.com/store/apps/details?id=com.sofialocks.luckey.macle'}
                    rel="noreferrer"
                  >
                    <img src={imgBadgeGooglePlay} className={classes.badge} alt="Badge GooglePlay" />
                  </a>
                </Box>
              </>
            )}
          </Box>
        </Paper>
      )}
      <Box>
        <QrCodeDialog open={open} onClose={onCloseDialog} qrcode={qrcode}></QrCodeDialog>
      </Box>
    </>
  );
};

export default SubscriptionItem;
