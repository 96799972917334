/**
 * SubscribeWorkflowContext.
 *
 * @description Manage subscribe workflow.
 */

import React, { createContext, ReactNode, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { useConfig, useIsAuthenticated, useFileProofOfAddress } from 'app/hooks';

type SubscribeWorkflow = {
  setDocument: (value: boolean) => void;
};

type SubscribeWorkflowProps = {
  children: ReactNode;
};

const initialSubscribeWorkflowContext: SubscribeWorkflow = {
  setDocument: (value: boolean) => {
    // nothing to do
  },
};

export const SubscribeWorkflowContext = createContext<SubscribeWorkflow>(initialSubscribeWorkflowContext);
SubscribeWorkflowContext.displayName = 'SubscribeWorkflowContext';

export const SubscribeWorkflowProvider = ({ children }: SubscribeWorkflowProps): JSX.Element => {
  const { history, location } = useReactRouter();
  const {
    rules: { provideProofOfAddress },
  } = useConfig();
  const isAuthenticated = useIsAuthenticated();
  const [{ fileProofOfAddress, isLoaded }] = useFileProofOfAddress();
  const [isDocument, setDocument] = useState(false);

  useEffect(() => {
    if (isAuthenticated && isLoaded && provideProofOfAddress && undefined !== fileProofOfAddress) {
      setDocument(true);
    }
  }, [fileProofOfAddress, isAuthenticated, isLoaded, location.pathname, provideProofOfAddress]);

  useEffect(() => {
    if (
      isAuthenticated &&
      provideProofOfAddress &&
      '/subscribe/proof-of-address' !== location.pathname &&
      isLoaded &&
      !isDocument &&
      undefined === fileProofOfAddress
    ) {
      history.push('/subscribe/proof-of-address');
    }
  }, [fileProofOfAddress, history, isAuthenticated, isDocument, isLoaded, location.pathname, provideProofOfAddress]);

  return <SubscribeWorkflowContext.Provider value={{ setDocument }}>{children}</SubscribeWorkflowContext.Provider>;
};
