import { useCallback } from 'react';
import { normalize } from 'normalizr';
import useApiClient from './useApiClient';
import { useEntitiesState } from './useEntities';
import { RESULT_SCHEMA, EntityRef } from '../schemas';
import merge from 'deepmerge';
import useEndpoints from './useEndpoints';

const overwriteMerge = (destinationArray: any[], sourceArray: any[], options: {}) => sourceArray;
const mergeEntities = (entities: any) => (prevEntities: any) =>
  merge(prevEntities, entities, { arrayMerge: overwriteMerge });

const useEntityManager = () => {
  const client = useApiClient();
  const [, setEntities] = useEntitiesState();

  const requestEntity = useCallback(
    async (entityIdentifier, extraConfig) => {
      const resourceUrl = entityIdentifier && entityIdentifier.startsWith('_') ? null : entityIdentifier;

      const response = await client.request({ url: resourceUrl, ...extraConfig });
      const parsedResult = { response, ...normalize<{}, EntityRef>(response.data || {}, RESULT_SCHEMA) };

      setEntities(mergeEntities(parsedResult.entities));
      return parsedResult;
    },
    [client, setEntities]
  );

  const fetchEntity = useCallback(
    (entityIdentifier, extraConfig = {}) => requestEntity(entityIdentifier, { method: 'get', ...extraConfig }),
    [requestEntity]
  );

  const fetchEntityList = useCallback(
    async (listIdentifier, extraConfig = { params: { fetchAll: true } }) => {
      let result: any = {};
      let pageToFetch = listIdentifier;
      do {
        const response = await client.request({ url: pageToFetch, ...extraConfig });
        result = merge(result, response);
        if (
          extraConfig.params &&
          extraConfig.params.fetchAll &&
          response.data &&
          response.data['hydra:view'] &&
          response.data['hydra:view']['hydra:next']
        ) {
          pageToFetch = response.data['hydra:view']['hydra:next'];
        } else pageToFetch = null;
      } while (pageToFetch !== null);
      const parsedResult = {
        // result,
        ...normalize<{}, EntityRef[]>(result.data['hydra:member'], [RESULT_SCHEMA]),
      };

      setEntities(mergeEntities(parsedResult.entities));
      return parsedResult;
    },
    [client, setEntities]
  );

  const fetchJsonList = useCallback(
    async (listIdentifier, extraConfig = { params: {} }) => {
      const pageToFetch = listIdentifier;
      const response = await client.request({ url: pageToFetch, ...extraConfig });
      const parsedResult = {
        response,
        ...normalize<{}, EntityRef[]>(response.data, [RESULT_SCHEMA]),
      };

      setEntities(mergeEntities(parsedResult.entities));
      return parsedResult;
    },
    [client, setEntities]
  );

  const postJson = useCallback(
    async (entityIdentifier, extraConfig = { params: {} }) => {
      const response = await client.request({ method: 'post', url: entityIdentifier, ...extraConfig });

      return response.data;
    },
    [client]
  );

  const postEntity = useCallback(
    (entityIdentifier, extraConfig = {}) => requestEntity(entityIdentifier, { method: 'post', ...extraConfig }),
    [requestEntity]
  );

  const putEntity = useCallback(
    (entityIdentifier, extraConfig = {}) => requestEntity(entityIdentifier, { method: 'put', ...extraConfig }),
    [requestEntity]
  );

  const deleteEntity = useCallback(
    async (entityIdentifier, extraConfig = {}) => {
      await requestEntity(entityIdentifier, { method: 'delete', ...extraConfig });
    },
    [requestEntity]
  );

  // don't add file actions, use apiClient instead
  // or a brand new useFileManager hook (with blob url handling)
  const actions = {
    fetchEntity,
    fetchEntityList,
    fetchJsonList,
    postEntity, // can be used to apply transitions
    putEntity,
    deleteEntity,
    postJson,
  };

  const endpoints = useEndpoints();

  return [actions, endpoints] as [typeof actions, typeof endpoints];
};

export default useEntityManager;
