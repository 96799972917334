import { Box, Container } from '@material-ui/core';
import { AccountCircleTwoTone, DateRangeTwoTone, PhonelinkRingTwoTone, SearchTwoTone } from '@material-ui/icons';
import { ComponentType, useEffect } from 'react';
import Grid from 'app/components/Grid';
import MenuButton from 'app/components/MenuButton';
import PageTitle from 'app/components/PageTitle';
import {
  useActiveSubscription,
  useConfig,
  useConnectedContact,
  useCurrentStudioBoxAssignation,
  useStudioWithState,
} from 'app/hooks';
import MessageBox from './Connected/MessageBox';
import SubscriptionBox from './Connected/SubscriptionBox';
import InnerLoader from 'app/components/InnerLoader';

const Connected: ComponentType = () => {
  /**
   * CONFIG
   */
  const rules = useConfig((config) => config.rules);

  /**
   * HOOKS
   */
  const [{ contact }] = useConnectedContact();
  const [{ activeSubscription, isLoaded: isLoadedSubscription }, { refresh: refreshSubscription }] =
    useActiveSubscription();
  const [, { refresh: refreshStudioWithState }] = useStudioWithState();
  const [{ studioBoxAssignation }, { fetch: refreshCurrentStudioBoxAssignation }] = useCurrentStudioBoxAssignation();

  /**
   * MEMOS
   */
  useEffect(() => {
    refreshStudioWithState();

    if (contact['@id']) {
      refreshCurrentStudioBoxAssignation(true);
      refreshSubscription();
    }
  }, [refreshCurrentStudioBoxAssignation, refreshStudioWithState, refreshSubscription, contact]);

  /**
   * RENDER
   */
  return (
    <>
      <PageTitle title={`Bonjour ${contact.givenName || ''} !`} />
      <Container maxWidth="sm">
        <Box py={3}>
          {(isLoadedSubscription && (
            <>
              <MessageBox />
              <SubscriptionBox />
            </>
          )) || <InnerLoader />}

          <Grid spacing={2} columns={2}>
            {rules.withReservation && (
              <>
                <MenuButton
                  disabled={!activeSubscription || studioBoxAssignation?.takeOffDate || !!studioBoxAssignation}
                  link={{ pathname: '/reservation', state: { withPrev: true } }}
                  title="Je réserve"
                  Icon={SearchTwoTone}
                />
                <MenuButton
                  link={{ pathname: '/my-reservation', state: { withPrev: true } }}
                  title="Ma réservation"
                  Icon={DateRangeTwoTone}
                  disabled={!studioBoxAssignation || studioBoxAssignation?.takeOffDate}
                  warning={studioBoxAssignation && studioBoxAssignation.expired}
                />
              </>
            )}
            <MenuButton
              link={{ pathname: '/contact', state: { withPrev: true } }}
              title="Nous contacter"
              Icon={PhonelinkRingTwoTone}
            />
            <MenuButton
              link={{ pathname: '/my-account', state: { withPrev: true } }}
              title="Mon compte"
              Icon={AccountCircleTwoTone}
            />
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Connected;
