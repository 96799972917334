import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ComponentType } from 'react';
import { useConfig } from 'app/hooks';
import { StudioType } from 'app/types';

const useStyles = makeStyles((theme) => ({
  mapPreview: {
    width: '100%',
    marginBottom: theme.spacing(2),
    border: '1px solid #cbcbcb',
  },
}));

type MapProps = {
  height?: number;
  item: StudioType;
  width?: number;
  zoom?: number;
};

const Map: ComponentType<MapProps> = ({
  height = 200,
  item: { name, xLatitude, xLongitude },
  width = 400,
  zoom = 14,
}): JSX.Element => {
  const classes = useStyles();
  const googleMaps = useConfig((config) => config.googleMaps);
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=${width}x${height}&markers=color:blue%7Clabel:S%7C${xLatitude},${xLongitude}&key=${googleMaps.apiKey}`;

  return (
    <Box>
      <Link href={`http://maps.google.com/?q=${xLatitude},${xLongitude}`} target="_blank">
        <img className={classes.mapPreview} alt={`Carte ${name}`} src={mapUrl} />
      </Link>
    </Box>
  );
};

export default Map;
