import { SnackbarContent, makeStyles } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import React, { ComponentType } from 'react';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText('#fff'),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
  },
}));

type NumberOfSubscriptionInfoMessageProps = {};

const NoRemainingGlobalSubscriptionInfoMessage: ComponentType<NumberOfSubscriptionInfoMessageProps> = () => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          Tous les abonnements ont été vendus, il n'y a plus d'abonnement à la vente pour le moment.
        </span>
      }
    />
  );
};

export default NoRemainingGlobalSubscriptionInfoMessage;
