import { schema } from 'normalizr';

const OFFER_SCHEMA = new schema.Entity('offers', {}, { idAttribute: '@id' });
const PRODUCT_BEHAVIOR_SCHEMA = new schema.Entity('productBehaviors', {}, { idAttribute: '@id' });
const PRODUCT_SCHEMA = new schema.Entity('products', {}, { idAttribute: '@id' });
const SHOWCASE_PRODUCT_SCHEMA = new schema.Entity('showcaseProducts', {}, { idAttribute: '@id' });

PRODUCT_SCHEMA.define({
  offers: [OFFER_SCHEMA],
  productBehaviors: [PRODUCT_BEHAVIOR_SCHEMA],
});

OFFER_SCHEMA.define({
  // product: PRODUCT_SCHEMA,
});

SHOWCASE_PRODUCT_SCHEMA.define({
  offer: OFFER_SCHEMA,
});

export default {
  'http://schema.org/Product': PRODUCT_SCHEMA,
  Offer: OFFER_SCHEMA,
  ShowcaseProduct: SHOWCASE_PRODUCT_SCHEMA,
};
