import { useCallback, useEffect, useState } from 'react';
import { useConnectedContact, useDenormalizedState, useEntityManager } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { FileProofOfAddressType } from 'app/types';
import createProvider from './createProvider';

const fileProofOfAddressSchema = createUnionSchema(['medical_certificates']);

const useFileProofOfAddress = () => {
  const [{ contact }] = useConnectedContact();
  const [isLoaded, setIsLoaded] = useState(false);
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const [fileProofOfAddress, setFileProofOfAddressResult] = useDenormalizedState<FileProofOfAddressType[]>(
    [],
    [fileProofOfAddressSchema]
  );

  const contactId = contact && contact['@id'];

  const fetch = useCallback(async () => {
    // retrieve access token, using provided code
    try {
      setIsLoaded(false);
      const data = await fetchEntityList(endpoints.FILE_PROOF_OF_ADDRESS, {
        params: {
          contact: contactId,
        },
      });
      setFileProofOfAddressResult(data.result);
      setIsLoaded(true);
    } catch (error) {
      // nothing to do
    }
  }, [contactId, endpoints.FILE_PROOF_OF_ADDRESS, fetchEntityList, setFileProofOfAddressResult]);

  useEffect(() => {
    if (contactId) {
      fetch();
    }
  }, [contactId, endpoints.FILE_PROOF_OF_ADDRESS, fetch, fetchEntityList]);

  const data = {
    fileProofOfAddress: fileProofOfAddress[0],
    isLoaded,
  };

  const actions = {
    fetch,
  };

  return [data, actions] as [typeof data, typeof actions];
};

const [withFileProofOfAddress, useProvidedFileProofOfAddress] = createProvider(useFileProofOfAddress);

export { withFileProofOfAddress };

export default useProvidedFileProofOfAddress;
