import React, { ComponentType } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    position: 'absolute',
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    borderRadius: '2px',
    listStyle: 'none',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      top: '2px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '5px',
      top: '2px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '8px',
      top: '5px',
    },
  },
  rootRight: {
    background: 'white',
    position: 'absolute',
    boxShadow: '0px 1px 4px -1px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    borderRadius: '2px',
    listStyle: 'none',
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      top: '90px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '5px',
      top: '65px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '8px',
      top: '5px',
      right: '5px',
    },
  },
  svg: {
    transform: 'translate(0, -1px)',
  },
  item: {
    float: 'left',
    marginRight: '10px',
    '&:last-child': {
      marginRight: 0,
    },
    '&.capacity': {
      fontWeight: 500,
    },
  },
  title: {
    marginBottom: '5px',
    textAlign: 'center',
  },
}));

const icon = (fillColor: string) => ({
  path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z',
  fillColor,
  fillOpacity: 1,
  scale: 1.5,
  scaledSize: { width: 30, height: 30 },
  anchor: { x: 15, y: 15 },
  strokeColor: 'black',
  strokeOpacity: 0.4,
  strokeWeight: 1.5,
});

interface colors {
  invalidColor: string;
  validColor: string;
  noDataColor: string;
  soonColor: string;
  brandColor: string;
  notSecured: string;
  notSecuredSoon: string;
}

type LegendProps = {
  colors: colors;
};

type IconSvgProps = {
  color: string;
  svgClass: any;
};

const IconSvg: ComponentType<IconSvgProps> = ({ color, svgClass }) => {
  const iconDefined = icon(color);

  return (
    <svg className={svgClass} height="24" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={iconDefined.fillColor}
        stroke={iconDefined.strokeColor}
        strokeOpacity={iconDefined.strokeOpacity}
        strokeWidth={iconDefined.strokeWeight}
        d={iconDefined.path}
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
};

const Legend: ComponentType<LegendProps> = ({ colors }) => {
  const classes = useStyles();

  return (
    <div>
      <ul className={classes.root}>
        <div className={classes.title}>Consignes sécurisées</div>
        <li className={classes.item}>
          <IconSvg color={colors.validColor} svgClass={classes.svg} /> Libre
        </li>
        <li className={classes.item}>
          <IconSvg color={colors.invalidColor} svgClass={classes.svg} /> Complet
        </li>
        <li className={classes.item}>
          <IconSvg color={colors.soonColor} svgClass={classes.svg} /> Bientôt disponible
        </li>
        <li className={classes.item}>
          <IconSvg color={colors.noDataColor} svgClass={classes.svg} /> Données indisponibles
        </li>
      </ul>
      <ul className={classes.rootRight}>
        <div className={classes.title}>Abris libre-accès</div>
        <li className={classes.item}>
          <IconSvg color={colors.notSecured} svgClass={classes.svg} /> Disponible
        </li>
        <li className={classes.item}>
          <IconSvg color={colors.notSecuredSoon} svgClass={classes.svg} /> Bientôt disponible
        </li>
      </ul>
    </div>
  );
};

export default Legend;
