import classNames from 'classnames';
import React, { ComponentType } from 'react';
import { StudioBoxType } from 'app/types';
import { Box, Button, Tooltip, makeStyles } from '@material-ui/core';
import ChildCareIconTwoTone from '@material-ui/icons/ChildCare';
import PowerIconTwoTone from '@material-ui/icons/Power';

type StudioBoxItemProps = {
  item: StudioBoxType;
  onClick?(): void;
  selected?: boolean;
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: '10px',
    borderRadius: 0,
    padding: 0,
    minWidth: 0,
    width: '50px',
    height: '50px',
    position: 'relative',
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
  },
  twoDBox: {
    display: 'flex',
    position: 'relative',
    textAlign: 'center',
    alignItems: 'top',
  },
  iconBox: {
    width: '15px',
    height: '15px',
    position: 'absolute',
    bottom: '2px',
  },
  icon: {
    width: '15px',
    height: '15px',
  },
}));

const StudioBoxItem: ComponentType<StudioBoxItemProps> = ({
  item: { boxOptions, free, name, locationX, locationY },
  onClick,
  selected,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.twoDBox} style={{ gridRow: `-${locationY}`, gridColumn: `${locationX}` }}>
      <Box>
        <Button
          disabled={!free || selected || undefined === onClick}
          variant="outlined"
          color="primary"
          className={classNames(classes.button, {
            [classes.selected]: selected,
          })}
          onClick={free ? onClick : undefined}
        >
          {(boxOptions.includes('child_seat') || boxOptions.includes('electrical_outlet')) && (
            <Box paddingBottom={1}>{name}</Box>
          )}
          {!boxOptions.includes('child_seat') && !boxOptions.includes('electrical_outlet') && <Box>{name}</Box>}
          <Box
            className={classes.iconBox}
            display="flex"
            flexDirection="row"
            alignItems="top"
            justifyContent="space-evenly"
          >
            {boxOptions.includes('child_seat') && (
              <Tooltip title="Emplacement avec siège enfant" aria-label="emplacement avec siège enfant">
                <ChildCareIconTwoTone className={classes.icon} fontSize="small" />
              </Tooltip>
            )}
            {boxOptions.includes('electrical_outlet') && (
              <Tooltip title="Emplacement avec prise électrique" aria-label="emplacement avec prise électrique">
                <PowerIconTwoTone className={classes.icon} fontSize="small" />
              </Tooltip>
            )}
          </Box>
        </Button>
      </Box>
    </div>
  );
};

export default StudioBoxItem;
