import React, { ComponentType } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Avatar, Typography, List, ListItem, ListItemText } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import Grid from 'app/components/Grid';
import { useAuthentication, useConnectedContact, useConfig } from 'app/hooks';
import { Person } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    margin: `${theme.spacing(1)}px 0`,
  },
  avatar: {
    width: 60,
    height: 60,
    margin: '0 auto 10px',
  },
  avatarIcon: {
    fontSize: 40,
  },
}));

const AppMenu: ComponentType = () => {
  const classes = useStyles();
  const [, { logout }] = useAuthentication();
  const [{ contact }] = useConnectedContact();
  const isLegalNoticeActive = useConfig((config) => config.legalNotice.active);
  const menu = useConfig((config) => config.menu);

  const handleLogout = () => {
    logout();
  };

  return (
    <Box p={2} display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      {contact ? (
        <div>
          <Avatar className={classes.avatar}>
            <Person className={classes.avatarIcon} />
          </Avatar>
          <Typography paragraph align="center" variant="h5">{`${contact.givenName} ${contact.familyName}`}</Typography>
          <Grid spacing={1} columns={1}>
            <Button color="primary" variant="contained" component={Link} to="/">
              Mon espace
            </Button>
            <Button color="primary" variant="outlined" onClick={handleLogout}>
              Me déconnecter
            </Button>
          </Grid>
        </div>
      ) : (
        <div>
          <Avatar className={classes.avatar}>
            <Person className={classes.avatarIcon} />
          </Avatar>
          <p>
            Connectez-vous afin de profiter du service. Si vous n'avez pas de compte, vous pouvez en créer un en vous
            abonnant.
          </p>
          <Button
            component={Link}
            to="/subscribe"
            className={classes.menuButton}
            fullWidth
            variant="contained"
            color="primary"
          >
            Je m'inscris
          </Button>
          <Button
            component={Link}
            to="/login"
            className={classes.menuButton}
            fullWidth
            variant="outlined"
            color="primary"
          >
            J'accède à mon compte
          </Button>
        </div>
      )}
      <List>
        <ListItem button component={Link} to={{ pathname: '/our-service', state: { withPrev: true } }} divider dense>
          <ListItemText primary={menu.service} />
        </ListItem>
        <ListItem button component={Link} to={{ pathname: '/our-offers', state: { withPrev: true } }} divider dense>
          <ListItemText primary={menu.offers} />
        </ListItem>
        <ListItem button component={Link} to={{ pathname: '/our-boxes', state: { withPrev: true } }} divider dense>
          <ListItemText primary={menu.boxes} />
        </ListItem>
        <ListItem button component={Link} to={{ pathname: '/our-rules', state: { withPrev: true } }} divider dense>
          <ListItemText primary="CGAU" />
        </ListItem>
        {isLegalNoticeActive && (
          <ListItem button component={Link} to={{ pathname: '/legal-notice', state: { withPrev: true } }} divider dense>
            <ListItemText primary="Mentions légales" />
          </ListItem>
        )}
        <ListItem button component={Link} to={{ pathname: '/contact', state: { withPrev: true } }} dense>
          <ListItemText primary="Contactez-nous" />
        </ListItem>
      </List>
    </Box>
  );
};

export default AppMenu;
