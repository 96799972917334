import React, { ComponentType } from 'react';
import { Box, Typography, Button, Container, Hidden } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import useReactRouter from 'use-react-router';
import { useWidth } from 'app/hooks';

const useStyles = makeStyles((theme) => ({
  iconPrev: {
    marginRight: '10px',
  },
  buttonPrev: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

type PageTitleProps = {
  title: string;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  bold?: boolean;
};

const PageTitle: ComponentType<PageTitleProps> = ({ title, maxWidth = 'md', children, bold = false }) => {
  const { location, history } = useReactRouter();
  const width = useWidth();
  const classes = useStyles();

  const { withPrev = false, prevLink = null } = location.state || {};
  const titlePadding = !withPrev || width === 'xs' ? 0 : 12;

  const onPrev = prevLink ? () => history.push(prevLink) : () => history.goBack();

  return (
    <Box component="div" pt={4} pb={2} borderBottom="2px solid" borderColor="grey.200" bgcolor="grey.50">
      <Container maxWidth={maxWidth}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flex={1}
          position="relative"
          px={titlePadding}
        >
          {withPrev && (
            <Hidden xsDown>
              <Button size="small" onClick={onPrev} className={classes.buttonPrev}>
                <ArrowBack className={classes.iconPrev} />
                Retour
              </Button>
            </Hidden>
          )}
          <Typography variant="h5" gutterBottom className={bold ? classes.bold : ''}>
            {title}
          </Typography>
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default PageTitle;
