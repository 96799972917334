import {
  Box,
  Button,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import React, { ComponentType, ChangeEvent, useState } from 'react';
import { useActiveSubscription, useScopeState, useStudioBoxes } from 'app/hooks';
import { StudioType, StudioBoxType } from 'app/types';
import Map from './Map';
import StudioBoxItem from './StudioBoxItem';
import StudioItemSummary from './StudioItemSummary';

const useStyles = makeStyles((theme) => ({
  expansionPanel: {
    marginBottom: theme.spacing(2),
  },
  expansionPanelDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  expansionPanelSummaryContent: {
    margin: '12px 0 !important',
  },
  expansionPanelSummary: {
    alignItems: 'flex-start',
  },
  expansionPanelDetails: {
    display: 'block',
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    padding: '24px',
  },
  loader: {
    color: theme.palette.primary.main,
  },
  paperDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  boxGrid: {
    display: 'inline-grid',
    gridAutoRows: '50px',
    gridAutoColumns: '50px',
    gridGap: '2px',
    border: `2px solid ${theme.palette.grey[200]}`,
    padding: '10px',
    marginBottom: '20px',
  },
}));

type StudioItemProps = {
  expanded?: boolean;
  item: StudioType;
  onChange?(event: ChangeEvent<{}>, isExpanded: boolean): void;
  onSelectStudioBox?(studioBox: StudioBoxType, studio?: StudioType): any;
  onSelectStudio?(studio: StudioType): any;
  onSelectStudioWaitingList?(studio: StudioType): any;
  withActiveWaitingList?: boolean;
  isSelected?: boolean;
};

const StudioItem: ComponentType<StudioItemProps> = ({
  expanded,
  item,
  onChange,
  onSelectStudioBox,
  onSelectStudio,
  onSelectStudioWaitingList,
  withActiveWaitingList,
  isSelected,
}): JSX.Element => {
  const studioId = item['@id'];
  const classes = useStyles();
  const [scope] = useScopeState();
  const [{ activeSubscription }] = useActiveSubscription();

  // Select Studio Box
  const [selectedStudioBox, setSelectedStudioBox] = useState<StudioBoxType>();
  const [
    {
      studioBoxes,
      isBoxesWithChildPlace,
      boxesWithChildPlace,
      isBoxesWithElectricalOutlet,
      boxesWithElectricalOutlet,
      remainingBoxes,
      isLoaded,
    },
  ] = useStudioBoxes(studioId);

  let localScope;

  if (scope === '' && activeSubscription) {
    localScope = activeSubscription.scope;
  } else {
    localScope = scope;
  }

  return undefined !== expanded ? (
    <ExpansionPanel
      disabled={!(item.state && ['can_waiting_list', 'can_subscribe', 'can_assignation'].includes(item.state))}
      expanded={expanded}
      onChange={onChange}
      classes={{
        root: 'can_waiting_list' === item.state ? classes.expansionPanelDisabled : undefined,
      }}
      className={classes.expansionPanel}
      style={{ minHeight: 150 }}
    >
      <ExpansionPanelSummary
        expandIcon={!isLoaded ? <CircularProgress size={22} className={classes.loader} /> : <ExpandMoreIcon />}
        classes={{
          content: classes.expansionPanelSummaryContent,
        }}
        className={classes.expansionPanelSummary}
      >
        <StudioItemSummary
          item={item}
          withActiveWaitingList={withActiveWaitingList}
          withInformationOnPlaces={{
            boxesWithChildPlace,
            remainingBoxes,
            isBoxesWithChildPlace,
            isBoxesWithElectricalOutlet,
            boxesWithElectricalOutlet,
          }}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        <Map item={item} />
        {localScope &&
          'studio_box' === localScope &&
          studioBoxes &&
          undefined !== item.state &&
          ['can_subscribe', 'can_assignation'].includes(item.state) && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <br />
              <Typography variant="body2" align="left" paragraph>
                Emplacement du vélo
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="top" justifyContent="space-between" mb={2}>
                <div className={classes.boxGrid}>
                  {studioBoxes.map((studioBox: StudioBoxType) => (
                    <StudioBoxItem
                      key={studioBox['@id']}
                      selected={selectedStudioBox && selectedStudioBox['@id'] === studioBox['@id']}
                      item={studioBox}
                      onClick={() => setSelectedStudioBox(studioBox)}
                    />
                  ))}
                </div>
              </Box>
              {onSelectStudioBox && (
                <Button
                  disabled={!selectedStudioBox}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => onSelectStudioBox(selectedStudioBox as StudioBoxType, item)}
                >
                  {'can_subscribe' === item.state && <>je commande</>}
                  {'can_assignation' === item.state && <>je réserve</>}
                </Button>
              )}
            </Box>
          )}
        {localScope && 'studio' === localScope && 'can_subscribe' === item.state && onSelectStudio && (
          <Box>
            <Button
              disabled={isSelected}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onSelectStudio(item)}
            >
              {isSelected ? 'Sélectionné !' : 'je commande'}
            </Button>
          </Box>
        )}
        {'can_waiting_list' === item.state && onSelectStudioWaitingList && (
          <Box>
            <Button fullWidth variant="contained" color="primary" onClick={() => onSelectStudioWaitingList(item)}>
              je m'inscris en liste d'attente
            </Button>
          </Box>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  ) : (
    <Paper
      elevation={1}
      className={classNames(classes.expansionPanel, {
        [classes.paperDisabled]:
          (undefined !== item.state && item.secured && ['can_waiting_list', 'soon', 'none'].includes(item.state)) ||
          undefined === item.state,
      })}
      style={{ height: '100%' }}
    >
      <Box py={1.5} px={3}>
        <StudioItemSummary
          item={item}
          withButtonByState
          withInformationOnPlaces={{
            boxesWithChildPlace,
            remainingBoxes,
            isBoxesWithChildPlace,
            isBoxesWithElectricalOutlet,
            boxesWithElectricalOutlet,
          }}
          withModalMap
        />
      </Box>
    </Paper>
  );
};

export default StudioItem;
