import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { ComponentType } from 'react';

type SimpleModalProps = {
  children: JSX.Element | JSX.Element[];
  handleClose: () => void;
  open: boolean;
  title?: string;
};

const SimpleModal: ComponentType<SimpleModalProps> = ({ children, handleClose, open, title }): JSX.Element => (
  <Dialog maxWidth="sm" onClose={handleClose} open={open}>
    <Box p={1}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Box>
  </Dialog>
);

export default SimpleModal;
