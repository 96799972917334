import { CircularProgress } from '@material-ui/core';
import React, { ComponentType } from 'react';

const MainLoader: ComponentType = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '25vh' }}>
      <CircularProgress />
    </div>
  );
};

export default MainLoader;
