import { useCallback, useMemo } from 'react';
import { useEntityManager, useConnectedContact } from 'app/hooks';

const useContactPreferences = () => {
  const [{ fetchEntityList, postEntity, putEntity }, endpoints] = useEntityManager();
  const [{ contact }] = useConnectedContact();

  const contactId = useMemo(() => {
    return contact && contact['@id'];
  }, [contact]);

  const fetchPreferences: any = useCallback(
    async (key: '') => {
      const params: any = {
        scope: 'altinnova_front',
        contact: contactId,
      };

      if ('' !== key) {
        params['key'] = key;
      }

      if (contactId) {
        try {
          const data = await fetchEntityList(endpoints.CONTACT_PREFERENCES, {
            params,
          });

          // Return only first result or null when getPreferences by key
          if ('' !== key) {
            if (data.result.length > 0) {
              return data.result[0];
            } else {
              return null;
            }
          }

          return data.result;
        } catch (error) {
          return null;
        }
      }
    },
    [endpoints.CONTACT_PREFERENCES, fetchEntityList, contactId]
  );

  const savePreference = useCallback(
    async (key, value, expiringAt = null) => {
      const currentValue = await fetchPreferences(key);

      if (currentValue) {
        try {
          await putEntity(currentValue['@id'], {
            data: {
              value: JSON.stringify(value),
              expiringAt,
            },
          });
        } catch (error) {
          // nothing to do
        }
      } else {
        try {
          await postEntity(endpoints.CONTACT_PREFERENCES, {
            data: {
              scope: 'altinnova_front',
              contact: contact['@id'],
              key,
              value: JSON.stringify(value),
              expiringAt,
            },
          });
        } catch (error) {
          // nothing to do
        }
      }
    },
    [fetchPreferences, putEntity, postEntity, endpoints.CONTACT_PREFERENCES, contact]
  );

  const actions = {
    fetchPreferences,
    savePreference,
  };

  return [actions] as [typeof actions];
};

export default useContactPreferences;
