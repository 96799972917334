import { Box, CircularProgress } from '@material-ui/core';
import React, { ComponentType } from 'react';

const InnerLoader: ComponentType = () => {
  return (
    <Box pt={4} pb={6} display={'flex'} justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

export default InnerLoader;
