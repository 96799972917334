import {
  Avatar,
  Box,
  Container,
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Person,
  EuroSymbolTwoTone,
  LockOpenTwoTone,
  ListAltTwoTone,
  HistoryTwoTone,
  AccountCircleTwoTone,
  VpnKey,
  Create,
} from '@material-ui/icons';
import React, { ComponentType, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useConnectedContact, useActiveSubscription, useConfig, useCurrentStudioBoxAssignation } from 'app/hooks';
import PageTitle from 'app/components/PageTitle';
import SubscriptionItem from 'app/components/SubscriptionItem';
import { FutureSubscriptionWarningMessage } from 'app/components/Subscriptions';
import InnerLoader from 'app/components/InnerLoader';
import MainLoader from 'app/components/MainLoader';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 32,
    height: 32,
    marginRight: '10px',
  },
  avatarIcon: {
    fontSize: 24,
  },
  listAvatar: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
}));

const MyAccount: ComponentType = () => {
  /**
   * HOOKS
   */
  const classes = useStyles();
  const rules = useConfig((config) => config.rules);
  const consent = useConfig((config) => config.consent);

  const [{ contact }, { refreshContact }] = useConnectedContact();
  const [{ activeSubscription, isLoaded: isLoadedSubscription, futureSubscription }, { refresh: refreshSubscription }] =
    useActiveSubscription();
  const [{ studioBoxAssignation, isLoaded: isLoadedStudioBoxAssignation }] = useCurrentStudioBoxAssignation();

  /**
   * REFRESH
   */
  // refresh contact when open page
  useEffect(() => {
    refreshContact();
  }, [refreshContact]);
  // refresh subscription when open page
  useEffect(() => {
    refreshSubscription();
  }, [refreshSubscription]);

  /**
   * RENDER
   */
  if (!contact) {
    return <MainLoader />;
  }

  return (
    <>
      <PageTitle title="Mon compte" />
      <Box>
        <Container maxWidth="sm">
          <Box pt={2} pb={3} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
            <Avatar className={classes.avatar}>
              <Person className={classes.avatarIcon} />
            </Avatar>
            <Typography align="left" variant="h6">
              {`${contact.givenName || ''} ${contact.familyName || ''}`}
            </Typography>
          </Box>

          {(isLoadedSubscription && (
            <>
              {!activeSubscription &&
                futureSubscription &&
                !(
                  isLoadedStudioBoxAssignation &&
                  studioBoxAssignation &&
                  'waiting_payment' === studioBoxAssignation.status &&
                  'pre_assigned' === studioBoxAssignation.type
                ) && (
                  <Box pb={2}>
                    <FutureSubscriptionWarningMessage />
                  </Box>
                )}
              {(activeSubscription || futureSubscription) && (
                <Box pb={2}>
                  <SubscriptionItem
                    // @ts-ignore
                    item={activeSubscription ? activeSubscription : futureSubscription}
                    title="Mon abonnement"
                    withAccessType
                  />
                </Box>
              )}
            </>
          )) || <InnerLoader />}

          <Box py={2}>
            <List>
              <ListItem
                button
                divider
                component={Link}
                to={{ pathname: '/my-account/past-subscriptions', state: { withPrev: true } }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.listAvatar}>
                    <HistoryTwoTone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body1" align="center">
                      Mes abonnements passés
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                divider
                component={Link}
                to={{ pathname: '/my-account/user-infos', state: { withPrev: true } }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.listAvatar}>
                    <AccountCircleTwoTone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body1" align="center">
                      Mes informations
                    </Typography>
                  }
                />
              </ListItem>
              {consent && (
                <ListItem
                  button
                  divider
                  component={Link}
                  to={{ pathname: '/my-account/consents', state: { withPrev: true } }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.listAvatar}>
                      <AccountCircleTwoTone />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1" align="center">
                        Consentements
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              <ListItem
                button
                divider
                component={Link}
                to={{ pathname: '/change-password', state: { withPrev: true } }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.listAvatar}>
                    <Create />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body1" align="center">
                      Modifier mon mot de passe
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                divider
                component={Link}
                to={{ pathname: '/my-account/invoice-history', state: { withPrev: true } }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.listAvatar}>
                    <EuroSymbolTwoTone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body1" align="center">
                      Historique de mes factures
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                divider
                component={Link}
                to={{ pathname: '/my-account/entry-history', state: { withPrev: true } }}
              >
                <ListItemAvatar>
                  <Avatar className={classes.listAvatar}>
                    <LockOpenTwoTone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body1" align="center">
                      Historique de mes accès
                    </Typography>
                  }
                />
              </ListItem>
              {'active' === rules.availableWaitingList && (
                <ListItem
                  button
                  divider
                  component={Link}
                  to={{ pathname: '/my-account/my-waiting-list', state: { withPrev: true } }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.listAvatar}>
                      <ListAltTwoTone />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1" align="center">
                        Ma liste d'attente
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              {(rules.addBadge || (isLoadedSubscription && (activeSubscription || futureSubscription))) && (
                <ListItem
                  button
                  divider
                  component={Link}
                  to={{ pathname: '/my-account/my-badge', state: { withPrev: true } }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.listAvatar}>
                      <VpnKey />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1" align="center">
                        Ma méthode d'accès
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MyAccount;
