import { Box, Button } from '@material-ui/core';
import React, { ComponentType, useMemo } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { StudioType } from 'app/types';
import { useConfig } from 'app/hooks';

type StudioItemSummarySubscribeButtonProps = {
  item: StudioType;
};

const StudioItemSummarySubscribeButton: ComponentType<StudioItemSummarySubscribeButtonProps> = ({ item }) => {
  return (
    <Box my={2} textAlign="center">
      {'can_subscribe' === item.state && (
        <Button fullWidth size="small" variant="contained" color="primary" component={LinkRouter} to="/login">
          je me connecte pour commander
        </Button>
      )}
      {'can_waiting_list' === item.state && (
        <Button fullWidth size="small" variant="contained" color="primary" component={LinkRouter} to="/login">
          je me connecte pour m'inscrire en liste d'attente
        </Button>
      )}
      {'can_candidacy' === item.state && (
        <Button fullWidth size="small" variant="contained" color="primary" component={LinkRouter} to="/login">
          je me connecte pour candidater
        </Button>
      )}
    </Box>
  );
};

export default (props: any) => {
  const clientToken = useConfig((config) => config.clientToken);
  const StudioItemSummarySubscribeButtonComponent = useMemo(
    () =>
      React.lazy(async () => {
        try {
          return await import('./StudioItemSummarySubscribeButton.' + clientToken);
        } catch (error) {
          return Promise.resolve({ default: StudioItemSummarySubscribeButton });
        }
      }),
    [clientToken]
  );

  return <StudioItemSummarySubscribeButtonComponent {...props} />;
};
