import { Button, SnackbarContent, makeStyles } from '@material-ui/core';
import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import amber from '@material-ui/core/colors/amber';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText(amber[200]),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontStyle: 'italic',
  },
}));

type SubscriptionExtensionMessageProps = {};

const SubscriptionExtensionMessage: ComponentType<SubscriptionExtensionMessageProps> = () => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={<span className={classes.message}>La date de fin de validité de votre abonnement approche.</span>}
      action={
        <Button size="small" component={Link} to="/subscriptions/extensions">
          Je prolonge
        </Button>
      }
    />
  );
};

export default SubscriptionExtensionMessage;
