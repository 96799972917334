import { useEffect, useState, useCallback, useMemo } from 'react';
import { useEntityManager, useDenormalizedState, useApiClient, useConnectedContact } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { IdentificatorType } from 'app/types';
import createProvider from './createProvider';

const identificatorSchema = createUnionSchema(['identificators']);

const useIdentificators = () => {
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const client = useApiClient();
  const [{ contact }] = useConnectedContact();
  const targetId = useMemo(() => {
    return contact?.['@id'];
  }, [contact]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [identificators, setIdentificators] = useDenormalizedState<IdentificatorType[]>([], [identificatorSchema]);

  const fetch = useCallback(async () => {
    if (targetId === undefined) {
      return;
    }
    setIsLoaded(false);
    try {
      const data = await fetchEntityList(endpoints.IDENTIFICATORS, {
        params: {
          targetId: targetId,
        },
      });
      setIdentificators(data.result);
      setIsLoaded(true);
      return data.result;
    } catch (error) {
      setIsLoaded(true);
    }
  }, [endpoints.IDENTIFICATORS, fetchEntityList, setIdentificators, targetId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const badges = useMemo(() => {
    return identificators.filter((item) => {
      return item.type === 'badge' && item.badgeTechnology === null;
    });
  }, [identificators]);

  const passPassBadges = useMemo(() => {
    return identificators.filter((item) => {
      return item.type === 'badge' && item.badgeTechnology === 'passpass';
    });
  }, [identificators]);

  const navigoBadges = useMemo(() => {
    return identificators.filter((item) => {
      return item.type === 'badge' && item.badgeTechnology === 'navigo';
    });
  }, [identificators]);

  const allBadges = useMemo(() => {
    return identificators.filter((item) => {
      return item.type === 'badge';
    });
  }, [identificators]);

  const qrCodes = useMemo(() => {
    return identificators.filter((item) => {
      return item.type === 'qrcode';
    });
  }, [identificators]);

  const isOnlyQrCodes = useMemo(() => {
    return qrCodes.length === identificators.length;
  }, [identificators.length, qrCodes.length]);

  const fetchQrCode = useCallback(
    async (qrCodeId?: any, ext = 'html') => {
      try {
        if (!qrCodeId) {
          qrCodeId = qrCodes[0]['@id'];
        }

        const response = await client.request({
          url: `${qrCodeId}/print.${ext}`,
          method: 'get',
          responseType: 'arraybuffer',
        });

        return response.data;
      } catch (error) {
        // nothing to do
      }
    },
    [client, qrCodes]
  );

  const syncIdpn = useCallback(
    async (contactId?: any) => {
      try {
        if (!contactId) {
          contactId = contact['@id'];
        }
        const response = await client.request({
          url: `${contactId}/idpn/sync`,
          method: 'post',
          data: { identificator_iri: navigoBadges[0]['@id'] },
          responseType: 'arraybuffer',
        });

        fetch();
        return response.data;
      } catch (error) {
        // nothing to do
      }
    },
    [client, contact, fetch, navigoBadges]
  );

  const actions = {
    refresh: fetch,
    fetchQrCode,
    syncIdpn,
  };

  const data = {
    identificators: identificators,
    badges: badges,
    passPassBadges: passPassBadges,
    navigoBadges: navigoBadges,
    allBadges: allBadges,
    qrCodes: qrCodes,
    isOnlyQrCodes,
    isLoaded,
  };

  return [data, actions] as [typeof data, typeof actions];
};

const [withIdentificators, useProvidedIdentificators] = createProvider(useIdentificators);

export { withIdentificators };

export default useProvidedIdentificators;
