import {
  Box,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field, Form, Formik, FormikActions } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import React, { ComponentType, ChangeEvent } from 'react';
import { useConfig } from 'app/hooks';
import { StudioType } from 'app/types';
import { Map } from 'app/components/Studios';
import StudioItemSummary from 'app/components/Studios/StudioItemSummary';

type CandidacyValues = {
  [key: string]: boolean;
};

const initialValues: CandidacyValues = {};

const useStyles = makeStyles((theme) => ({
  expansionPanel: {
    marginBottom: theme.spacing(2),
  },
  expansionPanelSummaryContent: {
    margin: '12px 0 !important',
  },
  expansionPanelDetails: {
    display: 'block',
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    padding: '24px',
  },
}));

type CandidacyItemProps = {
  expanded?: boolean;
  item: StudioType;
  onChange(event: ChangeEvent<{}>, isExpanded: boolean): void;
  onSelect(studio: StudioType, options: string[]): any;
};

const CandidacyItem: ComponentType<CandidacyItemProps> = ({ expanded, item, onChange, onSelect }): JSX.Element => {
  const classes = useStyles();
  const { candidateOptions } = useConfig((config) => config.rules);
  const { candidateOptions: candidateOptionsI18n } = useConfig((config) => config.i18n);

  return (
    <ExpansionPanel expanded={expanded} onChange={onChange} className={classes.expansionPanel}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          content: classes.expansionPanelSummaryContent,
        }}
      >
        <StudioItemSummary item={item} />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        <Map item={item} />
        <Formik
          onSubmit={async (values: CandidacyValues, actions: FormikActions<CandidacyValues>) => {
            await onSelect(
              item,
              Object.keys(values).filter((option) => values[option])
            );
            actions.setSubmitting(false);
          }}
          initialValues={initialValues}
        >
          {({ isSubmitting }) => (
            <Form>
              {undefined !== candidateOptions && (
                <Box mb={3} textAlign="left">
                  {candidateOptions.map((option) => (
                    <Field
                      key={option}
                      name={option}
                      Label={{
                        label: (
                          <Typography variant="body2">
                            {`Option : ${candidateOptionsI18n && candidateOptionsI18n[option].label}`}
                          </Typography>
                        ),
                      }}
                      component={CheckboxWithLabel}
                      variant="outlined"
                      margin="normal"
                      color="primary"
                    />
                  ))}
                </Box>
              )}
              <Box>
                <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                  Je candidate
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CandidacyItem;
