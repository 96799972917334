import React from 'react';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const Homepage: React.ComponentType = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h1">404: Not Found</Typography>
    </Container>
  );
};

export default Homepage;
