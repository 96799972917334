import { useCallback, useMemo } from 'react';
import { useEntityManager, useConnectedContact } from 'app/hooks';
import { now } from 'moment';

const useContactTags = () => {
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const [{ contact }] = useConnectedContact();

  const contactId = useMemo(() => {
    return contact && contact['@id'];
  }, [contact]);

  const fetchTags: any = useCallback(
    async (name: '', valid = true) => {
      const params: any = {
        contact: contactId,
      };

      if ('' !== name) {
        params['name'] = name;
      }

      if (true === valid) {
        params['validThrough[after]'] = new Date(now());
      }

      if (contactId) {
        try {
          const data = await fetchEntityList(endpoints.CONTACT_TAGS, {
            params,
          });

          return data.result;
        } catch (error) {
          return [];
        }
      }
      return [];
    },
    [endpoints.CONTACT_TAGS, fetchEntityList, contactId]
  );

  const actions = {
    fetchTags,
  };

  return [actions] as [typeof actions];
};

export default useContactTags;
