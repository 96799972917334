import React, { ComponentType, useEffect, useState } from 'react';
import { SnackbarContent, makeStyles, Button } from '@material-ui/core/';
import { useContactPreferences } from 'app/hooks';
import amber from '@material-ui/core/colors/amber';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText(amber[200]),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

type SubscriptionExtensionByCheckMessageProps = {
  refreshPreference: any;
};

const SubscriptionExtensionByCheckMessage: ComponentType<SubscriptionExtensionByCheckMessageProps> = ({
  refreshPreference,
}) => {
  const classes = useStyles();

  const [{ fetchPreferences, savePreference }] = useContactPreferences();
  const [checkExtensionPreference, setCheckExtensionPreference] = useState<any>();

  useEffect(() => {
    fetchPreferences('check_extension_message').then((preference: any) => {
      setCheckExtensionPreference(preference);
    });
  }, [fetchPreferences, setCheckExtensionPreference]);

  const onCloseClick = () => {
    savePreference(
      'check_extension_message',
      {
        waitingCheck: true,
        closed: true,
      },
      checkExtensionPreference ? checkExtensionPreference.expiringAt : null
    ).then(() => {
      refreshPreference();
    });
  };

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          Votre demande est en cours de traitement et sera validée à la réception du chèque.
        </span>
      }
      action={
        <Button size="small" onClick={onCloseClick}>
          J'ai compris
        </Button>
      }
    />
  );
};

export default SubscriptionExtensionByCheckMessage;
