import amber from '@material-ui/core/colors/amber';
import { SnackbarContent, makeStyles } from '@material-ui/core';
import React, { ComponentType, useEffect, useState } from 'react';
import { useDenormalizedState, useEntityManager } from 'app/hooks';
import { createUnionSchema } from 'app/schemas';
import { CandidacyType, StudioCandidacyType } from 'app/types';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[200],
    color: theme.palette.getContrastText(amber[200]),
    boxShadow: theme.shadows[1],
  },
  snackbarContentMessage: {
    width: '100%',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type CurrentCandidacyMessageProps = {
  currentCandidacy: CandidacyType;
};

const studioCandidaciesSchema = createUnionSchema(['studio_candidacies']);

const CurrentCandidacyMessage: ComponentType<CurrentCandidacyMessageProps> = ({ currentCandidacy }) => {
  const classes = useStyles();
  const studioId = currentCandidacy && currentCandidacy.studioCandidacy;
  const [{ fetchEntity }] = useEntityManager();
  const [isLoaded, setLoaded] = useState(false);
  const [studioCandidacy, setStudioCandidacy] = useDenormalizedState<StudioCandidacyType>({}, studioCandidaciesSchema);
  useEffect(() => {
    async function fetchStudioCandidacy() {
      setLoaded(false);
      try {
        const data = await fetchEntity(studioId);
        setStudioCandidacy(data.result);
        setLoaded(true);
      } catch (error) {
        // nothing to do
      }
    }

    if (studioId) fetchStudioCandidacy();
  }, [studioId, fetchEntity, setLoaded, setStudioCandidacy]);

  return (
    <SnackbarContent
      className={classes.warning}
      classes={{
        message: classes.snackbarContentMessage,
      }}
      message={
        <span className={classes.message}>
          Vous avez candidaté pour l'abri :&nbsp;
          <strong>{isLoaded && studioCandidacy && studioCandidacy.studio.name}</strong>
        </span>
      }
    />
  );
};

export default CurrentCandidacyMessage;
