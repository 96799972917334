import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, WithGoogleMapProps, WithScriptjsProps } from 'react-google-maps';
import { useStudioWithState, useLiveData, useConfig } from '../../hooks';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';
import List from 'app/components/GoogleMap/List';
import Legend from 'app/components/GoogleMap/Legend';
import { makeStyles } from '@material-ui/core/styles';
import { StudioType } from 'app/types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  mapContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '300px !important',
    },
    [theme.breakpoints.only('sm')]: {
      height: '370px !important',
    },
  },
}));

const icon = (fillColor: string) => ({
  path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z',
  fillColor,
  fillOpacity: 1,
  scale: 1.75,
  scaledSize: { width: 30, height: 35 },
  anchor: { x: 15, y: 15 },
  strokeColor: 'black',
  strokeOpacity: 0.5,
  strokeWeight: 1.8,
});

const colors = {
  invalidColor: '#e74c3C',
  validColor: '#27ae60',
  soonColor: '#e67e22',
  brandColor: '#b6543f',
  noDataColor: '#BEBEBC',
  notSecured: '#0076BD',
  notSecuredSoon: '#0bb9e7',
};

type MapCoreProps = {
  filteredStudios?: any;
};

const MapCore: ComponentType<MapCoreProps> = ({ filteredStudios }) => {
  const [{ liveStudioData }] = useLiveData();
  const classes = useStyles();
  const [{ studios }] = useStudioWithState();
  const clientMapConfig = useConfig((config) => config.mapDisplay);
  const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  let map: any;

  const activeStudios = useMemo(() => {
    if (filteredStudios && filteredStudios.length > 0) {
      return filteredStudios.filter((studio: StudioType) => {
        return studio.active;
      });
    }

    return studios.filter((studio: StudioType) => {
      return studio.active;
    });
  }, [studios, filteredStudios]);

  useEffect(() => {
    if (!isLoaded && map !== undefined) {
      if (activeStudios.length > 0) {
        // @ts-ignore
        const bounds = new window.google.maps.LatLngBounds();
        let count = 0;
        let data: any = {};
        const delta = 0.01;
        map.props.children.forEach((child: any, i: number) => {
          if (child !== undefined) {
            if (child.type === Marker) {
              bounds.extend(
                // @ts-ignore
                new window.google.maps.LatLng(Number(activeStudios[i].xLatitude), Number(activeStudios[i].xLongitude))
              );
              count++;
              if (count < 2) {
                data = { latitude: Number(activeStudios[i].xLatitude), longitude: Number(activeStudios[i].xLongitude) };
              }
            }
          }
        });
        if (1 === count) {
          // @ts-ignore
          bounds.extend(new window.google.maps.LatLng(data.latitude - delta, data.longitude - delta));
          // @ts-ignore
          bounds.extend(new window.google.maps.LatLng(data.latitude + delta, data.longitude + delta));
        }
        map.fitBounds(bounds);
      }

      setIsLoaded(true);
    }
  }, [isLoaded, map, activeStudios]);

  const onToggleOpen = (studioId: string) => {
    if (openInfoWindowMarkerId === studioId) {
      setOpenInfoWindowMarkerId(null);
    } else {
      setOpenInfoWindowMarkerId(studioId);
    }
  };

  return (
    <>
      {activeStudios.length > 0 && (
        <div className={classes.root}>
          <div className={classes.mapContainer}>
            <GoogleMap ref={(ref) => (map = ref)} defaultCenter={{ lat: 48.8534, lng: 2.3488 }} defaultZoom={6}>
              {activeStudios.map((studio: StudioType) => {
                let color = colors.invalidColor;
                const currentStudioLiveData = liveStudioData.find((item) => {
                  const studioIriSplitted = studio['@id'].split('/');
                  return item.id === Number(studioIriSplitted[studioIriSplitted.length - 1]);
                });

                // Define color by studio state
                if (clientMapConfig.remainingSubscriptions) {
                  if (studio.state === 'none' && !studio.secured) {
                    color = colors.notSecured;
                  } else if (studio.state === 'soon' && !studio.secured) {
                    color = colors.notSecuredSoon;
                  } else if (
                    (undefined !== studio.state && 'full' === studio.state) ||
                    (undefined !== studio.state && ['can_waiting_list', 'none'].includes(studio.state)) ||
                    undefined === studio.state
                  ) {
                    color = colors.invalidColor;
                  } else if (['can_candidacy', 'can_subscribe'].includes(studio.state)) {
                    color = colors.validColor;
                  } else if ('soon' === studio.state) {
                    color = colors.soonColor;
                  }
                }

                // If live data available, define color by that instead
                if (clientMapConfig.availablePlaces && currentStudioLiveData !== undefined) {
                  if (currentStudioLiveData.liveAvailablePlaces !== -1) {
                    if (currentStudioLiveData.liveAvailablePlaces > 0) {
                      color = colors.validColor;
                    } else {
                      color = colors.invalidColor;
                    }
                  }

                  // if no live data and no subscription display => noDataColor
                  if (currentStudioLiveData.liveAvailablePlaces === -1 && !clientMapConfig.remainingSubscriptions) {
                    color = colors.noDataColor;
                  }
                }

                // if no live data display and no subscription display => noDataColor
                if (!clientMapConfig.availablePlaces && !clientMapConfig.remainingSubscriptions) {
                  color = colors.noDataColor;
                }

                return (
                  <Marker
                    key={studio['@id']}
                    position={{ lat: Number(studio.xLatitude), lng: Number(studio.xLongitude) }}
                    icon={icon(color)}
                    onClick={() => onToggleOpen(studio['@id'])}
                  >
                    {openInfoWindowMarkerId === studio['@id'] && (
                      <InfoWindow key={studio['@id']} onCloseClick={() => onToggleOpen(studio['@id'])}>
                        <List studio={studio} colors={colors} studioLiveData={currentStudioLiveData} />
                      </InfoWindow>
                    )}
                  </Marker>
                );
              })}
            </GoogleMap>
          </div>
          <Legend colors={colors} />
        </div>
      )}
    </>
  );
};

export default withScriptjs(withGoogleMap(MapCore)) as ComponentType<
  WithScriptjsProps & WithGoogleMapProps & MapCoreProps
>;
