import React, { ComponentType, useMemo } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import { useConfig } from 'app/hooks';

const defaultTheme = createMuiTheme();

export const withThemeProvider =
  (WrappedComponent: ComponentType): ComponentType =>
  (props) => {
    const theme = useConfig((config) => config.theme);
    const muiTheme = useMemo(
      () =>
        createMuiTheme({
          palette: {
            text: {
              primary: theme.textColor || defaultTheme.palette.text.primary,
            },
            primary: { main: theme.primaryColor },
            secondary: { main: theme.secondaryColor || theme.primaryColor },
            contrastThreshold: 2,
            background: {
              default: '#f3f3f3',
            },
          },
          typography: {
            h1: {
              lineHeight: 1.1,
              fontFamily: theme.customFont || 'Montserrat, Helvetica, Arial, sans-serif;',
            },
            h2: {
              lineHeight: 1.1,
              fontFamily: theme.customFont || 'Montserrat, Helvetica, Arial, sans-serif;',
            },
            h3: {
              lineHeight: 1.1,
              fontFamily: theme.customFont || 'Montserrat, Helvetica, Arial, sans-serif;',
            },
            h4: {
              lineHeight: 1.1,
              fontFamily: theme.customFont || 'Montserrat, Helvetica, Arial, sans-serif;',
            },
            h5: {
              lineHeight: 1.1,
              fontFamily: theme.customFont || 'Montserrat, Helvetica, Arial, sans-serif;',
            },
            h6: {
              lineHeight: 1.1,
              fontFamily: theme.customFont || 'Montserrat, Helvetica, Arial, sans-serif;',
            },
            body1: {
              fontFamily: theme.customFont || 'Montserrat, Helvetica, Arial, sans-serif;',
            },
            body2: {
              lineHeight: 1.2,
              fontFamily: theme.customFont || 'Montserrat, Helvetica, Arial, sans-serif;',
            },
          },
          props: {
            MuiAppBar: {
              color: 'default',
            },
            MuiTextField: {
              variant: 'outlined',
              margin: 'normal',
              InputLabelProps: {
                shrink: true,
              },
            },
          },
          overrides: {
            MuiAppBar: {
              colorDefault: {
                backgroundColor: theme.headerColor || '#FFF',
              },
            },
            MuiButton: {
              contained: {
                backgroundColor: '#FFF',
              },
            },
          },
        }),
      [theme.headerColor, theme.primaryColor, theme.secondaryColor, theme.textColor, theme.customFont]
    );

    return (
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <WrappedComponent {...props} />
      </ThemeProvider>
    );
  };

export default withThemeProvider;
