import { Button, SnackbarContent, makeStyles } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
    boxShadow: theme.shadows[1],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  warningButton: {
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
}));

type MultipleActiveSubscriptionErrorMessageProps = {};

const MultipleActiveSubscriptionErrorMessage: ComponentType<MultipleActiveSubscriptionErrorMessageProps> = () => {
  const classes = useStyles();

  return (
    <SnackbarContent
      className={classes.warning}
      message={
        <span className={classes.message}>
          <Error fontSize="small" className={classes.icon} />
          Vous avez actuellement deux abonnements valide en même temps. Merci de contacter rapidement le support.
        </span>
      }
      action={
        <Button className={classes.warningButton} size="small" component={Link} to="/contact">
          Contact
        </Button>
      }
    />
  );
};

export default MultipleActiveSubscriptionErrorMessage;
