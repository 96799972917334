import { Box, Button, Chip, Paper, Typography } from '@material-ui/core';
import { lightGreen, deepOrange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { ComponentType } from 'react';
import useReactRouter from 'use-react-router';
import { useEntityManager } from 'app/hooks';
import { StudioBoxType, StudioType } from 'app/types';
import Map from './Map';
import StudioBoxItem from './StudioBoxItem';

const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: '5px',
    backgroundColor: lightGreen[500],
    color: '#fff',
  },
  chipError: {
    backgroundColor: deepOrange[500],
  },
  buttonWaitingList: {
    margin: '20px 0',
    backgroundColor: 'white',
  },
  waitingList: {
    marginBottom: '24px',
    backgroundColor: '#d6d6d6',
  },
}));

type StudioResumeProps = {
  isOnWaitingList?: string;
  studio: StudioType;
  withInformationOnPlaces?: {
    boxesWithChildPlace: number;
    selectedStudioBox?: StudioBoxType;
    studioBoxes?: StudioBoxType[];
    remainingBoxes: number;
  };
  withStatusDisplay?: boolean;
};

const StudioResume: ComponentType<StudioResumeProps> = ({
  isOnWaitingList,
  studio,
  withInformationOnPlaces,
  withStatusDisplay,
}): JSX.Element => {
  const classes = useStyles();
  const { history } = useReactRouter();
  const [{ deleteEntity }] = useEntityManager();

  const handleRemoveWaitingList = async () => {
    try {
      await deleteEntity(isOnWaitingList);
      history.replace({ pathname: '/' });
    } catch (error) {
      // nothing to do
    }
  };

  return (
    <Paper elevation={1} className={classNames({ [classes.waitingList]: isOnWaitingList })}>
      <Box py={1.5} px={3}>
        <Box display="flex" flexDirection="row" alignItems="top" my={2} minHeight="80px">
          <Box width="150px" textAlign="center">
            <Typography variant="h6" component="div" align="center" gutterBottom>
              {studio.name}
            </Typography>
            {withStatusDisplay &&
              undefined !== studio.state &&
              ['can_candidacy', 'can_subscribe'].includes(studio.state) && (
                <Chip size="small" label={'disponible'} className={classes.chip} />
              )}
            {withStatusDisplay &&
              ((undefined !== studio.state && ['can_waiting_list', 'none'].includes(studio.state)) ||
                undefined === studio.state) && (
                <Chip size="small" label={'indisponible'} className={classNames(classes.chip, classes.chipError)} />
              )}
            {withStatusDisplay && undefined !== studio.state && 'soon' === studio.state && (
              <Chip size="small" label={'bientôt disponible'} className={classNames(classes.chip, classes.chipError)} />
            )}
          </Box>
          <Box display="flex" flexDirection="column" ml={3}>
            <Typography align="left" variant="body2" gutterBottom>
              {studio.streetAddress}
            </Typography>
            <Typography align="left" variant="body2" gutterBottom>
              {`${studio.postalCode} ${studio.addressLocality}`}
            </Typography>
            {withInformationOnPlaces && (
              <Typography align="left" variant="body2" gutterBottom>
                {0 === withInformationOnPlaces.remainingBoxes ? (
                  'Aucune place disponible'
                ) : (
                  <>
                    <strong>{withInformationOnPlaces.remainingBoxes}</strong> place(s) disponible(s) dont{' '}
                    <strong>{withInformationOnPlaces.boxesWithChildPlace}</strong> place(s) siège enfant
                  </>
                )}
              </Typography>
            )}
          </Box>
        </Box>

        {studio.xLatitude && studio.xLongitude && <Map item={studio} height={100} />}

        {withInformationOnPlaces &&
          withInformationOnPlaces.studioBoxes &&
          withInformationOnPlaces.studioBoxes.length > 0 && (
            <Box>
              <Typography align="left" variant="body2" gutterBottom>
                Ma place dans le box
              </Typography>

              <Box display="flex" flexDirection="row" alignItems="top" justifyContent="space-between" mb={2}>
                {withInformationOnPlaces.selectedStudioBox &&
                  withInformationOnPlaces.studioBoxes.map((studioBox: StudioBoxType) => (
                    <StudioBoxItem
                      key={studioBox['@id']}
                      selected={
                        withInformationOnPlaces.selectedStudioBox &&
                        withInformationOnPlaces.selectedStudioBox['@id'] === studioBox['@id']
                      }
                      item={studioBox}
                    />
                  ))}
              </Box>
            </Box>
          )}

        {isOnWaitingList && (
          <Box>
            <Typography gutterBottom align="center" variant="body2">
              Vous êtres inscris en liste d'attente pour cet abri.
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              onClick={handleRemoveWaitingList}
              className={classes.buttonWaitingList}
            >
              Je me retire de la liste d'attente
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default StudioResume;
