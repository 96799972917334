import { useCallback, useMemo } from 'react';
import { useEntityManager, useConnectedContact } from 'app/hooks';
import { OptinType } from 'app/types';

const useOptins = () => {
  const [{ fetchEntityList }, endpoints] = useEntityManager();
  const [{ contact }] = useConnectedContact();

  const contactId = useMemo(() => {
    return contact && contact['@id'];
  }, [contact]);

  const fetchOptin = useCallback(
    async (type: String, channel: String): Promise<{ [id: string]: OptinType }> => {
      const params: any = {
        contact: contactId,
      };

      if ('' !== type) {
        params['type'] = type;
      }

      if ('' !== channel) {
        params['channel'] = channel;
      }

      if (contactId) {
        try {
          const data: any = await fetchEntityList(endpoints.OPTINS, {
            params,
          });
          return data.entities.optins;
        } catch (error) {
          return {};
        }
      }
      return {};
    },
    [endpoints.OPTINS, fetchEntityList, contactId]
  );

  const actions = {
    fetchOptin,
  };

  return [actions] as [typeof actions];
};

export default useOptins;
